import { Avatar, Divider, Typography, Grid, Button } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import endPoints from "../../config/endPoint";
import axios from "axios";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";
import { EditorState, convertFromRaw } from "draft-js";
import { MdLogout } from "react-icons/md";
import url from "../../API";
import DashboardHeader from "../../components/DashHeader/Index";
import ImageWithPopup from "../../components/ImagewithPop/Index";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
export default function Bloglist() {
  const classes = countryListStyles();
  const [list, setList] = React.useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const [searchText, setSearchText] = React.useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deleteblogId, setDeleteblogId] = React.useState(null);
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(url + "blog/getallblogs", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      setList(actualData.Blog);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table();
    if (localStorage.getItem("createBlog")) {
      toast.success("Created Sucessfully", {
        position: "top-center",
      });
      localStorage.removeItem("createBlog");
    }
    if (localStorage.getItem("updateBlogsus")) {
      toast.success("Updated Sucessfully", {
        position: "top-center",
      });
      localStorage.removeItem("updateBlogsus");
    }
  }, []);
  const handleDelete = (idx) => {
    setDeleteblogId(idx);
    setConfirmDialogOpen(true);
  };

  const handleDeleteCountry = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: deleteblogId };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(url + "blog/deleteblog", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        toast.success("Deleted Sucessfully", {
          position: "top-center",
        });
        table();
      }
    } catch (err) {
      console.log(err);
    }
    setConfirmDialogOpen(false);
  };
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>Blog List</p>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) => {
                navigate("/blog");
              }}
            >
              Create Blog
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Title",
              "Thumbnail",
              "Main Image",
              "Description",
              "Date",
              "Written By",
              "Action",
            ]}
            rows={[
              ...list
                .sort()
                .filter((l) => {
                  const title = l.title?.toLowerCase() || "";
                  const written = l.written_by?.toLowerCase() || "";
                  const description =
                    JSON.parse(l.description)?.blocks[0].text?.toLowerCase() ||
                    "";
                  const searchLower = searchText.toLowerCase();
                  return (
                    title.includes(searchLower) ||
                    description.includes(searchLower) ||
                    written.includes(searchLower) ||
                    searchLower === undefined
                  );
                })

                .map((vs, idx) => [
                  idx + 1,
                  vs.title,
                  // <Avatar
                  //   variant="rounded"
                  //   alt="VISA"
                  //   src={vs.thumbnail_image}
                  //   style={{
                  //     margin: "auto",
                  //   }}
                  // />,

                  <ImageWithPopup
                    src={vs.thumbnail_image}
                    alt="Example Image"
                  />,
                  // <Avatar
                  //   variant="rounded"
                  //   alt="VISA"
                  //   src={vs.main_image}
                  //   style={{
                  //     margin: "auto",
                  //   }}
                  // />,
                  <ImageWithPopup src={vs.main_image} alt="Example Image" />,

                  JSON.parse(vs.description).blocks[0].text,
                  vs.date,
                  vs.written_by,
                  <span className={classes.actionBtn}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      onClick={(e) => handleDelete(vs.id)}
                      icon={<FaTimes />}
                    />
                    <CustomButton
                      variant="contained"
                      color="warning"
                      onClick={(e) => {
                        // localStorage.setItem("updateBlog", JSON.stringify(vs));
                        navigate("/blog", {
                          state: { updateBlog: vs },
                        });
                      }}
                      icon={<FaEdit />}
                    />
                  </span>,
                ]),
            ]}
          />
        </div>
        <ToastContainer />
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleDeleteCountry}
      />
    </div>
  );
}
