import { Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Divider } from "@mui/material";
import { AiFillFlag } from "react-icons/ai";
import DashboardLayout from "../../layouts";
import countryStyles from "../../styles/pages/CountryPage/index.style";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import axios from "axios";
import endPoints from "../../config/endPoint";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { Avatar } from "@mui/material";
import TimePicker from "react-time-picker";
import { FaTimes, FaUserFriends } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import "../../styles/DescriptionStyle.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { MdLogout } from "react-icons/md";
import { useLocation } from "react-router-dom";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import url from "../../API";
import { makeStyles } from "@mui/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { identity } from "lodash";
import ImageWithPopup from "../../components/ImagewithPop/Index";
import classNames from "classnames";
import { TextField, Box } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { stateToHTML } from "draft-js-export-html";
// Library to convert draft-js to HTML
import DashboardHeader from "../../components/DashHeader/Index";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
export default function InformationPage() {
  const classes = countryStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const [loading, setLoading] = React.useState(false);
  const data = localStorage.getItem("data");
  const parsedData = JSON.parse(data);
  const [visaInformation, setVisaInformation] = React.useState([]);
  const [visaPhotos, setVisaPhotos] = React.useState([]);
  const [fee_details, setFeedetails] = React.useState([]);
  const [visaPrevPics, setVisaPics] = React.useState([]);
  const [visaId, setVisaId] = React.useState("");
  const [logoPhotos, setlogoPhotos] = React.useState([]);
  const [time_cat, setTime_cat] = React.useState([]);
  const [map_info, setMap_info] = React.useState([]);
  const [partnerWrk, setPartnerwrk] = React.useState([]);
  const location = useLocation();
  const { updateId } = location.state || {};
  const [feeList, setFeelist] = React.useState([]);
  const [fileName2, setFileName2] = React.useState("No file chosen");
  const [countryInfo, setCountryinfo] = React.useState([]);
  const useStyles = makeStyles((theme) => ({
    select: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#787878",
        },
        "&:hover fieldset": {
          borderColor: "#565656",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#333333",
        },
      },
    },
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: "#787878 !important",
        color: "white",
      },
    },
  }));
  const class2 = useStyles();
  const [fileName, setFileName] = React.useState("No file chosen");
  const menuProps = {
    classes: { paper: class2.select },
    MenuListProps: {
      classes: { root: class2.option },
    },
  };
  const [state, setState] = React.useState({
    name: "",
    marketing_price: "",
    visa_thumbnail: "",
    visa_pricing: "",
    visa_time: "",
    visa_timeId: null,
    receive_days: "",
    //benifits: "",
    is_comingsoon: "",
    is_visa_issued_on_arriving: "",
    is_visa_required: "",
    entry_type: [],
    entry_id: [],
    citizenship_country: [],
    citizenship_countryId: [],

    //  receive_time: "",
    validity_period: "",
    validity_description: "",
    from_country: "",
    from_country_id: "",
    to_country: "",
    to_country_id: "",
    visa_photos: [],
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
    isUpdate: false,
    visaId: null,
    time_mapId: null,
    fee_info: [
      {
        fee_type: "",
        fee_amount: "",
        fee_type_id: "",
        fee_description: "",
        discounted_price: "",
      },
    ],
    flight_change_over_description: "",
    benifits: EditorState.createEmpty(),
    faq_info: [{ question: "", answer: EditorState.createEmpty(), id: "" }],
    priceCmp_info: [{ provided_name: "", price: "", id: "" }],
    customer_testimonial: [
      {
        id: "",
        name: "",
        image: "",
        description: EditorState.createEmpty(),
        date: "",
        location: "",
        title: "",
        rating: "",
      },
    ],
    docs: [
      {
        docs_icon: "",
        docs_title: "",
        docs_id: "",
      },
    ],
    immigration: [
      {
        position: "",
        image: "",
        name: "",
        type: "",
        id: "",
      },
    ],
    question_info: [
      {
        type_question: "",
        type_list: [
          "text input",
          "Number",
          "Upload",
          "Date Picker",
          "Toggle",
          "Text Area",
          "Drop Down",
          "Check box",
          "Radio",
        ],
        question_type: "",
        question_type_id: "",
        name: "",
        choice: [],
        required: "",
        id: null,
      },
    ],
    // question: "",
    // answer: EditorState.createEmpty(),
    logo: [],
  });

  // const convertTime = (time24) => {
  //   const [hoursStr, minutesStr] = time24.split(":");
  //   let hours = parseInt(hoursStr, 10);
  //   const minutes = parseInt(minutesStr, 10);
  //   let meridiem = "AM";

  //   if (hours >= 12) {
  //     meridiem = "PM";
  //     hours %= 12;
  //   }

  //   if (hours === 0) {
  //     hours = 12; // 12 AM
  //   }

  //   return `${hours}:${minutes < 10 ? "0" : ""}${minutes}${meridiem}`;
  // };
  // const convertTo24HourFormat = (timeStr) => {
  //   const [hours, mins] = timeStr.split(/[: ]/);
  //   let formattedHours = parseInt(hours, 10);
  //   const meridiem = mins.slice(-2);
  //   console.log(meridiem);
  //   var zeroVal = "0o0";
  //   const minutes = mins.slice(0, -2) == "NaN" ? zeroVal : mins.slice(0, -2);

  //   if (meridiem.toUpperCase() === "PM" && formattedHours !== 12) {
  //     formattedHours += 12;
  //   } else if (meridiem.toUpperCase() === "AM" && formattedHours === 12) {
  //     formattedHours = 0;
  //   }

  //   const formattedTime = `${formattedHours
  //     .toString()
  //     .padStart(2, "0")}:${minutes}`;
  //   // return timeStr.slice(0, -2);
  //   return formattedTime;
  // };
  // const handleTimeChange = (newTime) => {
  //   const formattedTime = newTime.toLocaleString("en-US", {
  //     hour: "numeric",
  //     minute: "2-digit",
  //     hour12: true,
  //   });
  //   setState({
  //     ...state,
  //     receive_time: convertTime(formattedTime),
  //   });
  // };
  // const handleChange = (event: SelectChangeEvent[typeof state.entry_type]) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setState({
  //     ...state,
  //     entry_type: typeof value === "string" ? value.split(",") : value,
  //   });
  // };
  const removelogoPhotos = (index, chk) => {
    if (chk == "prev") {
      const newImages = [...logoPhotos];
      newImages.splice(index, 1);
      localStorage.removeItem(`image${index + 1}`);
      setlogoPhotos(newImages);
    } else {
      const newImages = [...state.logo];
      newImages.splice(index, 1);
      localStorage.removeItem(`image${index + 1}`);
      setState({ ...state, logo: newImages });
    }
  };
  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "entrytype/getallentrytype",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setVisaInformation(actualData.EntryType);
    } catch (e) {
      console.log(e);
    }
  }
  async function table3() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(url + "feetypes/getallfeetypes", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      setFeelist(actualData.Fee_Types);
      return actualData.Fee_Types;
    } catch (e) {
      console.log(e);
    }
  }
  async function table2() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(url + "country/getallcountry", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      setCountryinfo(actualData.Country);
      return actualData.Country;
    } catch (e) {
      console.log(e);
    }
  }
  async function table4() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "visatimecategory/getallvisatimecategory",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setTime_cat(actualData.Visa_Time_Category);
      return actualData.Visa_Time_Category;
    } catch (e) {
      console.log(e);
    }
  }
  async function table5() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "visatimecategorymap/getallvisatimecategorymap",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setMap_info(actualData.VisaTimeCategoryMap);
      return actualData.VisaTimeCategoryMap;
    } catch (e) {
      console.log(e);
    }
  }
  // async function table5() {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }
  //     const updateobj = JSON.parse(localStorage.getItem("updateVisa"));
  //     const data = { visa_id: updateobj.id };
  //     const requestOptions = {
  //       method: "POST",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(url + "visa/faqdetailsbyvisaid", requestOptions);
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     setfaqvisa(actualData.Question_Answer_details);
  //     return actualData.Question_Answer_details;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  // async function table6() {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }
  //     const updateobj = JSON.parse(localStorage.getItem("updateVisa"));
  //     const data = { visa_id: updateobj.id };
  //     const requestOptions = {
  //       method: "POST",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(
  //       url + "visa/partnerweworkwithdetailsbyvisaid",
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     setfaqvisa(actualData.Partners_Details);
  //     const pics = actualData.Partners_Details.map((item) => item.logo);
  //     setlogoPhotos(pics);
  //     return actualData.Partners_Details;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  // async function table7() {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }
  //     const updateobj = JSON.parse(localStorage.getItem("updateVisa"));
  //     const data = { visa_id: updateobj.id };
  //     const requestOptions = {
  //       method: "POST",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(
  //       url + "visa/customertestimonialdetailsbyvisaid",
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     setfaqvisa(actualData.Customer_Details);
  //     return actualData.Customer_Details;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  const handleStateChange = (name, value) => {
    if (!!name) {
      setState({
        ...state,
        [name]: value,
      });
    }
  };
  const handleEntryTypeChange = (v) => {
    const selectedEntryTypeName = v;

    const selectedEntryType = visaInformation.find(
      (item) => item.entry_type_name === selectedEntryTypeName
    );

    setState((prevState) => ({
      ...prevState,
      entry_type: v,
      entry_id: selectedEntryType ? selectedEntryType.id : "",
    }));
    console.log(state);
  };
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const tableData = await table();
        const countryInfoData = await table2();
        const fee_typeData = await table3();
        const time_cat = await table4();
        if (updateId) {
          var id = null,
            visa_time = null,
            visa_time_category_id = null;
          try {
            const loginHeaders = new Headers();
            loginHeaders.append("Content-Type", "application/json");

            // Assuming you have an authorization token stored in localStorage
            const authToken = localStorage.getItem("tkn");
            if (authToken) {
              loginHeaders.append("Authorization", `Bearer ${authToken}`);
            }
            const data = { visa_id: updateId };
            const requestOptions = {
              method: "POST",
              headers: loginHeaders,
              body: JSON.stringify(data),
            };
            const res = await fetch(
              url + "visa/visadetailsbyvisaid",
              requestOptions
            );
            const actualData = await res.json();
            const visaDetails = actualData.visa_details;
            const maptable_info = await table5();
            const categoryMapEntry = maptable_info.find(
              (entry) => entry.visa_id === visaDetails.id
            );
            console.log(categoryMapEntry);
            if (!categoryMapEntry) {
              // If no matching entry is found, return null values

              id = null;
              visa_time_category_id = null;
              visa_time = null;
            } else {
              // Find the matching entry in Visa_Time_Category
              const timeCategoryEntry = time_cat.find(
                (entry) => entry.id === categoryMapEntry.visa_time_category_id
              );

              if (!timeCategoryEntry) {
                // If no matching entry is found, return the map id, visa_time_category_id and null for visa_time

                id = categoryMapEntry.id;
                visa_time_category_id = categoryMapEntry.visa_time_category_id;
                visa_time = null;
              } else {
                // Return the map id, matched visa_time_category_id and visa_time

                id = categoryMapEntry.id;
                visa_time_category_id = categoryMapEntry.visa_time_category_id;
                visa_time = timeCategoryEntry.visa_time;
              }
            }
            setVisaPics(actualData.visa_photos_details);
            const questionTypes = [
              "text input",
              "Number",
              "Upload",
              "Date Picker",
              "Toggle",
              "Text Area",
              "Drop Down",
              "Check box",
              "Radio",
            ];

            const formattedQuestions = actualData.questions_with_answers.map(
              (questionWithAnswers) => {
                const { question, answers } = questionWithAnswers;
                const typeQuestion = [7, 8, 9].includes(question.question_type)
                  ? "multiple"
                  : "";

                const choices =
                  typeQuestion === "multiple"
                    ? answers.map((answer) => ({ choice_title: answer.choice }))
                    : [];

                return {
                  type_question: typeQuestion,
                  type_list: questionTypes,
                  question_type:
                    questionTypes[question.question_type - 1] || "",
                  question_type_id: question.question_type,
                  name: question.question_name,
                  choice: choices,
                  required: question.required_or_not,
                  id: question.id,
                };
              }
            );

            const pics = actualData.visa_photos_details.map(
              (item) => item.visa_photos
            );
            console.log(actualData.entry_type_details.entry_type_name);
            setVisaPhotos(pics);
            const tableData = await table();
            setState((prevState) => ({
              ...prevState,
              name: visaDetails.visa_name || "",

              visa_thumbnail: visaDetails.visa_thumbnail || "",
              visa_pricing: visaDetails.visa_pricing || "",
              receive_days: visaDetails.receive_days || "",
              marketing_price: visaDetails.marketing_fee || "",
              is_comingsoon:
                visaDetails.is_comingsoon == true ? "Yes" : "No" || false,
              is_visa_issued_on_arriving:
                visaDetails.is_visa_issued_on_arriving == true
                  ? "Yes"
                  : "No" || false,
              is_visa_required:
                visaDetails.is_visa_required == true ? "Yes" : "No" || false,
              entry_type: actualData.entry_type_details
                ? actualData.entry_type_details.map(
                    (entry) => entry.entry_type_name
                  )
                : [],
              entry_id: actualData.entry_type_details
                ? actualData.entry_type_details.map((entry) => entry.id)
                : [],
              validity_period: visaDetails.validity_period || "",
              validity_description: visaDetails.validity_description || "",
              from_country_id: visaDetails.from_country_id || "",
              from_country: actualData.from_country_name || "",
              to_country: actualData.to_country_name || "",
              to_country_id: visaDetails.to_country_id || "",
              meta_title: visaDetails.meta_title || "",
              meta_keywords: visaDetails.meta_keywords || "",
              meta_description: visaDetails.meta_description || "",
              citizenship_country: visaDetails.citizenship_country || [],
              fee_info: actualData.visa_fee_details.map((fee, idx) => ({
                fee_type: actualData.visa_type_details[idx].fee_name || "",
                fee_amount: fee.price || "",
                fee_type_id: fee.feetype_id || "",
                fee_description: fee.descriptions || "",
                discounted_price: fee.discount_price || "",
                id: fee.id || null,
              })),
              flight_change_over_description:
                visaDetails.flight_change_over_descriptions,

              benifits: visaDetails.benifits
                ? EditorState.createWithContent(
                    convertFromRaw(JSON.parse(visaDetails.benifits))
                  )
                : EditorState.createEmpty(),
              faq_info: actualData.faq_for_visa_data.map((faq) => ({
                question: faq.question || "",
                answer: faq.answer
                  ? EditorState.createWithContent(
                      convertFromRaw(JSON.parse(faq.answer))
                    )
                  : EditorState.createEmpty(),
                id: faq.id || "",
              })),
              priceCmp_info: actualData.Price_compair_data.map((cmp) => ({
                price: cmp.price || "",
                provided_name: cmp.provided_name || "",
                id: cmp.id || "",
              })),
              customer_testimonial: actualData.customer_testimonials.map(
                (testimonial) => ({
                  id: testimonial.id || "",
                  name: testimonial.customer_name || "",
                  image: testimonial.customer_image || "",
                  description: testimonial.description
                    ? EditorState.createWithContent(
                        convertFromRaw(JSON.parse(testimonial.description))
                      )
                    : EditorState.createEmpty(),
                  date: testimonial.date || "",
                  location: testimonial.customer_location || "",
                  title: testimonial.title || "",
                  rating: testimonial.rating || "",
                })
              ),
              docs: actualData.document_required_details.map((doc) => ({
                docs_icon: doc.document_icon || "",
                docs_title: doc.document_name || "",
                docs_id: doc.id || "",
              })),
              immigration: actualData.immigration_officer_data.map(
                (officer) => ({
                  position: officer.officer_position || "",
                  image: officer.officer_photo || "",
                  name: officer.officer_name || "",
                  type: officer.officer_type || "",
                  id: officer.id || "",
                })
              ),
              logo: actualData.partner_logos || [],
              visaId: visaDetails.id,
              isUpdate: true,
              time_mapId: id,

              visa_time: visa_time,
              visa_timeId: visa_time_category_id,
              question_info: formattedQuestions,
            }));
          } catch (e) {
            console.log(e);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors if necessary
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {}, [
    countryInfo,
    feeList,
    visaInformation,
    fee_details,
  ]);
  React.useEffect(() => {
    const totalAmount = state.fee_info.reduce((sum, item) => {
      const amount = parseFloat(item.fee_amount) || 0;
      return sum + amount;
    }, 0);

    setState((prevState) => ({
      ...prevState,
      visa_pricing: totalAmount.toFixed(2), // Sets the visa_pricing to the total amount with two decimal places
    }));
  }, [state.fee_info]);
  const removePhotos = (index, chk, imgstr) => {
    if (chk == "prev") {
      const newImages = [...visaPhotos];
      newImages.splice(index, 1);
      var matchingObject = visaPrevPics.find(
        (obj) => obj.visa_photos === imgstr
      );
      axios
        .post(
          url + "visaphotos/deletevisaphotos",
          {
            id: matchingObject.id,
            //description pass the text only
            //remove the thumbnail image
            //json pasrse string to send description
            // visa_time: state.visa_time,
          },
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((r) => {
          matchingObject = {};
          console.log(r);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // Handle error here
        });
      localStorage.removeItem(`image${index + 1}`);
      setVisaPhotos(newImages);
    } else {
      const newImages = [...state.visa_photos];
      newImages.splice(index, 1);
      localStorage.removeItem(`image${index + 1}`);

      setState({ ...state, visa_photos: newImages });
    }
  };
  console.log(state, "state");

  const handleUpdateInfo = async (id) => {
    setLoading(true); // Start loading
    var visapics = visaPhotos.concat(state.visa_photos);
    visapics = visapics.filter((item) => item !== null && item !== undefined);
    console.log(visapics);

    const requiredFields = [
      state.visa_thumbnail,
      state.visa_pricing,
      state.receive_days,
      state.is_comingsoon,
      state.is_visa_issued_on_arriving,
      state.is_visa_required,
      state.flight_change_over_description,

      state.from_country_id,
      state.to_country_id,
      state.validity_period,
      state.validity_description,
      state.meta_title,
      state.meta_keywords,
      state.meta_description,
      state.name,
    ];

    if (requiredFields.some((field) => field === "" || field === false)) {
      toast.error("Provide all basic information", {
        position: "top-center",
      });
      return;
    }

    try {
      const visaInfoResponse = await axios.post(
        url + "visainformation/updatevisainformation",
        {
          visa_name: state.name,
          visa_thumbnail: state.visa_thumbnail,
          visa_pricing: state.visa_pricing,
          receive_days: state.receive_days,
          is_comingsoon: state.is_comingsoon == "Yes" ? true : false,
          is_visa_issued_on_arriving:
            state.is_visa_issued_on_arriving == "Yes" ? true : false,
          is_visa_required: state.is_visa_required == "Yes" ? true : false,
          flight_change_over_descriptions: state.flight_change_over_description,

          id: id,
          validity_period: state.validity_period,
          validity_description: state.validity_description,
          visa_photos: visapics,
          from_country_id: state.from_country_id,
          to_country_id: state.to_country_id,
          meta_title: state.meta_title,
          meta_keywords: state.meta_keywords,
          meta_description: state.meta_description,
          marketing_fee: state.marketing_price,
          citizenship_country: state.citizenship_country,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (visaInfoResponse.status === 200) {
        for (let i = 0; i < state.fee_info.length; i++) {
          const fee = state.fee_info[i];

          // Validate required fields
          if (
            fee.fee_amount === "" ||
            fee.fee_type_id === "" ||
            fee.fee_description === ""
          ) {
            toast.error("Please provide the fee information", {
              position: "top-center",
            });
            return;
          }

          try {
            if (fee.id) {
              // Update visa fee logic
              const update_data = {
                visa_id: id, // Assuming `id` is provided for updates
                id: fee.id,
                feetype_id: fee.fee_type_id,
                price: fee.fee_amount,
                descriptions: fee.fee_description,
                is_discount_available: fee.discounted_price ? true : false,
              };

              if (fee.discounted_price) {
                update_data.discount_price = fee.discounted_price;
              }

              await axios.post(url + "visafees/updatevisafees", update_data, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
            } else {
              // Create visa fee logic
              const create_data = {
                visa_id: id, // Assuming `visaId` is provided for creation
                feetype_id: fee.fee_type_id,
                price: parseInt(fee.fee_amount),
                descriptions: fee.fee_description,
                is_discount_available: fee.discounted_price ? true : false,
              };

              if (fee.discounted_price) {
                create_data.discount_price = fee.discounted_price;
              }

              await axios.post(url + "visafees/createvisafees", create_data, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
            }
          } catch (error) {
            console.error("Error processing fee:", error);
            toast.error("An error occurred while processing the fee.", {
              position: "top-center",
            });
          }
        }

        for (let i = 0; i < state.faq_info.length; i++) {
          const faq = state.faq_info[i];
          if (
            faq.question === "" ||
            !faq.answer || // Check if answer is defined
            !faq.answer.getCurrentContent || // Check if it has the method
            !faq.answer.getCurrentContent().hasText()
          ) {
            toast.error("Please provide the faq information", {
              position: "top-center",
            });
            return;
          } else if (faq.id === "") {
            await axios.post(
              url + "faqforvisa/createfaqforvisa",
              {
                question: faq.question,
                answer: JSON.stringify(
                  convertToRaw(faq.answer.getCurrentContent())
                ),
                visa_id: id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            await axios.post(
              url + "faqforvisa/updatefaqforvisa",
              {
                visa_id: id,
                question: faq.question,
                answer: JSON.stringify(
                  convertToRaw(faq.answer.getCurrentContent())
                ),
                id: faq.id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }
        for (let i = 0; i < state.priceCmp_info.length; i++) {
          const price_cmp = state.priceCmp_info[i];
          if (
            price_cmp.price === "" ||
            price_cmp.provided_name === "" // Check if answer is defined
          ) {
            toast.error("Please provide the faq information", {
              position: "top-center",
            });
            return;
          } else if (price_cmp.id === "") {
            await axios.post(
              url + "pricecompair/createpricecompair",
              {
                provided_name: price_cmp.provided_name,
                price: price_cmp.price,
                visa_id: id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            await axios.post(
              url + "pricecompair/updatepricecompair",
              {
                visa_id: id,
                provided_name: price_cmp.provided_name,
                price: price_cmp.price,
                id: price_cmp.id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }

        for (let i = 0; i < state.customer_testimonial.length; i++) {
          const test = state.customer_testimonial[i];
          if (
            test.name === "" ||
            test.date === "" ||
            test.image === "" ||
            !test.description || // Check if description is defined
            !test.description.getCurrentContent || // Check if it has the method
            !test.description.getCurrentContent().hasText() ||
            test.location === "" ||
            test.title === "" ||
            test.rating === ""
          ) {
            toast.error("Please provide the customer testimonial information", {
              position: "top-center",
            });
            return;
          } else if (test.id === "") {
            await axios.post(
              url + "customertestimonial/createcustomertestimonial",
              {
                customer_name: test.name,
                customer_image: test.image,
                description: JSON.stringify(
                  convertToRaw(test.description.getCurrentContent())
                ),
                customer_location: test.location,
                title: test.title,
                date: test.date,
                visa_id: id,
                rating: test.rating,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            await axios.post(
              url + "customertestimonial/updatecustomertestimonial",
              {
                customer_name: test.name,
                customer_image: test.image,
                description: JSON.stringify(
                  convertToRaw(test.description.getCurrentContent())
                ),
                customer_location: test.location,
                title: test.title,
                date: test.date,
                rating: test.rating,
                id: test.id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }

        for (let i = 0; i < state.docs.length; i++) {
          const doc = state.docs[i];
          if (doc.docs_title === "" || doc.docs_icon === "") {
            toast.error("Please provide the required document information", {
              position: "top-center",
            });
            return;
          } else if (doc.docs_id === "") {
            await axios.post(
              url + "documentrequired/createdocumentrequired",
              {
                visa_id: id,
                document_icon: doc.docs_icon,
                document_name: doc.docs_title,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            await axios.post(
              url + "documentrequired/updatedocumentrequired",
              {
                visa_id: id,
                id: doc.docs_id,
                document_icon: doc.docs_icon,
                document_name: doc.docs_title,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }

        for (let i = 0; i < state.immigration.length; i++) {
          const officer = state.immigration[i];
          if (
            officer.position === "" ||
            officer.image === "" ||
            officer.name === "" ||
            officer.type === ""
          ) {
            toast.error(
              "Please provide the required immigration officer information",
              {
                position: "top-center",
              }
            );
            return;
          } else if (officer.id === "") {
            const officerResponse = await axios.post(
              url + "immigrationofficerlist/createimmigrationofficerlist",
              {
                officer_name: officer.name,
                officer_photo: officer.image,
                officer_position: officer.position,
                officer_type: officer.type,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            await axios.post(
              url + "visaofficermap/createvisaofficermap",
              {
                visa_id: id,
                officer_id: officerResponse.data.new_immigration_officer_id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            await axios.post(
              url + "immigrationofficerlist/updateimmigrationofficerlist",
              {
                officer_name: officer.name,
                officer_photo: officer.image,
                officer_position: officer.position,
                officer_type: officer.type,
                id: officer.id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }
        for (let i = 0; i < state.question_info.length; i++) {
          const question = state.question_info[i];

          if (question.question_type_id === "" || question.name === "") {
            toast.error("Please provide the required document information", {
              position: "top-center",
            });
            return;
          } else if (question.id == null) {
            if ([7, 8, 9].includes(question.question_type_id)) {
              const data = question.choice.map((item) => item.choice_title);
              axios
                .post(
                  url + "question/createquestion",
                  {
                    visa_id: updateId,
                    question_name: question.name,
                    question_type: question.question_type_id,
                    choice: data,
                    required_or_not: question.required,
                    //  description: JSON.stringify(question.description), // Assuming you have a description field
                  },
                  {
                    headers: {
                      "Content-Type": "application/json", // Example header
                      Authorization: `Bearer ${token}`, // Example authorization header
                    },
                  }
                )
                .then((r) => {
                  console.log(r);
                })
                .catch((error) => {
                  console.error("Error fetching data:", error);
                  // Handle error here
                });
            } else {
              axios
                .post(
                  url + "question/createquestion",
                  {
                    visa_id: updateId,
                    question_name: question.name,
                    question_type: question.question_type_id,
                    required_or_not: question.required,
                    //  description: JSON.stringify(question.description), // Assuming you have a description field
                  },
                  {
                    headers: {
                      "Content-Type": "application/json", // Example header
                      Authorization: `Bearer ${token}`, // Example authorization header
                    },
                  }
                )
                .then((r) => {
                  console.log(r);
                })
                .catch((error) => {
                  console.error("Error fetching data:", error);
                  // Handle error here
                });
            }
          } else {
            if ([7, 8, 9].includes(question.question_type_id)) {
              const data = question.choice.map((item) => item.choice_title);
              axios
                .post(
                  url + "question/updatequestion",
                  {
                    id: question.id,
                    visa_id: updateId,
                    question_name: question.name,
                    question_type: question.question_type_id,
                    choice: data,
                    required_or_not: question.required,
                    // description: JSON.stringify(question.description), // Assuming you have a description field
                  },
                  {
                    headers: {
                      "Content-Type": "application/json", // Example header
                      Authorization: `Bearer ${token}`, // Example authorization header
                    },
                  }
                )
                .then((r) => {
                  console.log(r);
                  // Navigate only after the last request is done (optional)
                })
                .catch((error) => {
                  console.error("Error fetching data:", error);
                  // Handle error here
                });
            } else {
              axios
                .post(
                  url + "question/updatequestion",
                  {
                    id: question.id,
                    visa_id: updateId,
                    question_name: question.name,
                    question_type: question.question_type_id,
                    choice: [],
                    required_or_not: question.required,
                    // description: JSON.stringify(question.description), // Assuming you have a description field
                  },
                  {
                    headers: {
                      "Content-Type": "application/json", // Example header
                      Authorization: `Bearer ${token}`, // Example authorization header
                    },
                  }
                )
                .then((r) => {
                  console.log(r);
                  // Navigate only after the last request is done (optional)
                })
                .catch((error) => {
                  console.error("Error fetching data:", error);
                  // Handle error here
                });
            }
          }
        }

        await axios.post(
          url + "partnerweworkwith/updatepartnerweworkwith",
          {
            visa_id: id,
            logo: state.logo,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await axios.post(
          url + "visaentrytypemap/updatevisaentrytypemap",
          {
            visa_id: id,

            // id: localStorage.getItem("entrymapId"),
            entry_type_id: state.entry_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (state.time_mapId) {
          await axios.post(
            url + "visatimecategorymap/updatevisatimecategorymap",
            {
              id: state.time_mapId,
              visa_id: id,
              visa_time_category_id: state.visa_timeId,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          await axios.post(
            url + "visatimecategorymap/createvisatimecategorymap",
            {
              visa_id: id,
              visa_time_category_id: state.visa_timeId,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }

        navigate("/information_list");
        localStorage.setItem("updateVisa", 1);
        setState({
          ...state,
          name: "",
          visa_thumbnail: "",
          visa_pricing: "",
          receive_days: "",
          is_comingsoon: false,
          is_visa_issued_on_arriving: false,
          is_visa_required: false,
          flight_change_over_description: "",
          benifits: EditorState.createEmpty(),
          from_country_id: "",
          to_country_id: "",
          validity_period: "",
          validity_description: "",
          visa_photos: [],
          meta_title: "",
          meta_keywords: "",
          meta_description: "",
          fee_info: [],
          faq_info: [],
          customer_testimonial: [],
          docs: [],
          immigration: [],
          logo: [],
        });
      }
    } catch (error) {
      console.error("Error updating visa information: ", error);
      toast.error("Error updating visa information", {
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  };
  console.log("nnn", state);

  const handleCreateInfo = async () => {
    const requiredFields = [
      state.visa_thumbnail,
      state.visa_pricing,
      state.receive_days,
      state.is_comingsoon,
      state.is_visa_issued_on_arriving,
      state.is_visa_required,
      state.flight_change_over_description,

      state.from_country_id,
      state.to_country_id,
      state.validity_period,
      state.validity_description,
      state.meta_title,
      state.visa_photos,
      state.meta_keywords,
      state.meta_description,
      state.name,
    ];

    if (requiredFields.some((field) => field === "" || field === false)) {
      toast.error("Please provide the basic information", {
        position: "top-center",
      });
      return;
    }

    try {
      const visaInfoResponse = await axios.post(
        url + "visainformation/createvisainformation",
        {
          visa_name: state.name,
          visa_thumbnail: state.visa_thumbnail,
          visa_pricing: state.visa_pricing,
          receive_days: state.receive_days,
          is_comingsoon: state.is_comingsoon === "Yes" ? true : false,
          is_visa_issued_on_arriving:
            state.is_visa_issued_on_arriving === "Yes" ? true : false,
          is_visa_required: state.is_visa_required === "Yes" ? true : false,
          flight_change_over_descriptions: state.flight_change_over_description,

          validity_period: state.validity_period,
          validity_description: state.validity_description,
          visa_photos: state.visa_photos,
          from_country_id: state.from_country_id,
          to_country_id: state.to_country_id,
          meta_title: state.meta_title,
          meta_keywords: state.meta_keywords,
          meta_description: state.meta_description,
          citizenship_country: state.citizenship_country,
          marketing_fee: state.marketing_price,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const visaId = visaInfoResponse.data.visa_id;
      setVisaId(visaId);
      localStorage.removeItem("thumbnail");

      // Fee Info
      if (state.fee_info.length > 0 && state.fee_info[0].fee_amount !== "") {
        for (let i = 0; i < state.fee_info.length; i++) {
          const fee = state.fee_info[i];
          if (
            fee.fee_amount === "" ||
            fee.fee_type_id === "" ||
            fee.fee_description === ""
          ) {
            toast.error("Please provide the fee information", {
              position: "top-center",
            });
            return;
          } else {
            const fee_data = {
              visa_id: visaId,
              feetype_id: fee.fee_type_id,
              price: parseInt(fee.fee_amount),
              descriptions: fee.fee_description,
              is_discount_available: fee.discounted_price ? true : false,
            };
            if (fee.discounted_price) {
              fee_data.discount_price = fee.discounted_price;
            }

            await axios.post(
              url + "visafees/createvisafees ",
              fee_data,
              // {
              //   visa_id: visaId,
              //   feetype_id: fee.fee_type_id,
              //   price: parseInt(fee.fee_amount),
              //   descriptions: fee.fee_description,
              // },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }
      }

      // FAQ Info
      if (state.faq_info.length > 0 && state.faq_info[0].question !== "") {
        for (let i = 0; i < state.faq_info.length; i++) {
          const faq = state.faq_info[i];
          if (
            faq.question === "" ||
            !faq.answer.getCurrentContent().hasText()
          ) {
            toast.error("Please provide the FAQ information", {
              position: "top-center",
            });
            return;
          }

          await axios.post(
            url + "faqforvisa/createfaqforvisa",
            {
              visa_id: visaId,
              question: faq.question,
              answer: JSON.stringify(
                convertToRaw(faq.answer.getCurrentContent())
              ),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
      }

      // Customer Testimonial
      if (
        state.customer_testimonial.length > 0 &&
        state.customer_testimonial[0].name !== ""
      ) {
        for (let i = 0; i < state.customer_testimonial.length; i++) {
          const test = state.customer_testimonial[i];
          if (
            test.name === "" ||
            test.date === "" ||
            test.image === "" ||
            !test.description.getCurrentContent().hasText() ||
            test.location === "" ||
            test.title === "" ||
            test.rating === ""
          ) {
            toast.error("Please provide the customer testimonial information", {
              position: "top-center",
            });
            return;
          }

          await axios.post(
            url + "customertestimonial/createcustomertestimonial",
            {
              customer_name: test.name,
              customer_image: test.image,
              description: JSON.stringify(
                convertToRaw(test.description.getCurrentContent())
              ),
              customer_location: test.location,
              title: test.title,
              date: test.date,
              visa_id: visaId,
              rating: test.rating,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
      }

      // Required Documents
      if (state.docs.length > 0 && state.docs[0].docs_title !== "") {
        for (let i = 0; i < state.docs.length; i++) {
          const doc = state.docs[i];
          if (doc.docs_title === "" || doc.docs_icon === "") {
            toast.error("Please provide the required document information", {
              position: "top-center",
            });
            return;
          }

          await axios.post(
            url + "documentrequired/createdocumentrequired",
            {
              visa_id: visaId,
              document_icon: doc.docs_icon,
              document_name: doc.docs_title,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
      }

      // Immigration Officer
      if (
        state.immigration.length > 0 &&
        state.immigration[0].position !== ""
      ) {
        for (let i = 0; i < state.immigration.length; i++) {
          const officer = state.immigration[i];
          if (
            officer.position === "" ||
            officer.image === "" ||
            officer.name === "" ||
            officer.type === ""
          ) {
            toast.error(
              "Please provide the required immigration officer information",
              {
                position: "top-center",
              }
            );
            return;
          }

          const officerResponse = await axios.post(
            url + "immigrationofficerlist/createimmigrationofficerlist",
            {
              officer_name: officer.name,
              officer_photo: officer.image,
              officer_position: officer.position,
              officer_type: officer.type,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          await axios.post(
            url + "visaofficermap/createvisaofficermap",
            {
              visa_id: visaId,
              officer_id: officerResponse.data.new_immigration_officer_id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
      }

      // Questions
      if (
        state.question_info.length > 0 &&
        state.question_info[0].name !== ""
      ) {
        for (let i = 0; i < state.question_info.length; i++) {
          const question = state.question_info[i];
          if (
            question.name === "" ||
            question.question_type_id === "" ||
            question.required === ""
          ) {
            toast.error("Please provide the question information", {
              position: "top-center",
            });
            return;
          }

          if ([7, 8, 9].includes(question.question_type_id)) {
            const data = question.choice.map((item) => item.choice_title);
            await axios.post(
              url + "question/createquestion",
              {
                visa_id: visaId,
                question_name: question.name,
                question_type: question.question_type_id,
                choice: data,
                required_or_not: question.required,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            await axios.post(
              url + "question/createquestion",
              {
                visa_id: visaId,
                question_name: question.name,
                question_type: question.question_type_id,
                required_or_not: question.required,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        }
      }

      // Partner We Work With
      if (state.logo.length > 0) {
        await axios.post(
          url + "partnerweworkwith/createpartnerweworkwith",
          {
            visa_id: visaId,
            logo: state.logo,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      if (
        state.priceCmp_info.length > 0 &&
        state.priceCmp_info[0].provided_name !== ""
      ) {
        for (let i = 0; i < state.priceCmp_info.length; i++) {
          const price_cmp = state.priceCmp_info[i];
          if (price_cmp.price === "" || price_cmp.provided_name === "") {
            toast.error("Please provide the Price Compare information", {
              position: "top-center",
            });
            return;
          }

          await axios.post(
            url + "pricecompair/createpricecompair",
            {
              visa_id: visaId,
              provided_name: price_cmp.provided_name,
              price: price_cmp.price,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
      }
      // Visa Entry Type Map
      await axios.post(
        url + "visaentrytypemap/createvisaentrytypemap",
        {
          visa_id: visaId,
          entry_type_id: state.entry_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      //visa time cat
      await axios.post(
        url + "visatimecategorymap/createvisatimecategorymap",
        {
          visa_id: visaId,
          visa_time_category_id: state.visa_timeId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setState({
        name: "",
        visa_thumbnail: "",
        visa_pricing: "",
        receive_days: "",
        is_comingsoon: false,
        is_visa_issued_on_arriving: false,
        is_visa_required: false,
        flight_change_over_description: "",
        benifits: EditorState.createEmpty(),
        from_country_id: "",
        to_country_id: "",
        validity_period: "",
        validity_description: "",
        visa_photos: [],
        meta_title: "",
        meta_keywords: "",
        meta_description: "",
        fee_info: [],
        faq_info: [],
        customer_testimonial: [],
        docs: [],
        immigration: [],
        logo: [],
      });

      navigate("/information_list");
      localStorage.setItem("createVisa", 1);
    } catch (error) {
      console.error("Error creating visa information: ", error);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };
  const deleteVisaFee = (id) => {
    axios
      .post(
        url + "visafees/deletevisafees",
        {
          id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r) => {
        // if (r.data.status == 200) {
        //   toast.success("Deleted Sucessfully", {
        //     position: "top-center",
        //   });
        //   table();
        // }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    
  };
  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        </>
      ) : (
        <>
          <div className={classes.container}>
            <DashboardLayout />
            <div className={classes.contentSection}>
              <DashboardHeader />

              <Divider className={classes.divider} />
              <div
                className={classes.contentHead}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {" "}
                  <p style={{ marginTop: "2px", fontSize: "22px" }}>
                    Visa Information Form
                  </p>
                </div>
              </div>

              <Divider className={classes.divider} />
              <Grid container spacing={4} className={classes.contentMain}>
                <Grid item xs={12} md={3}>
                  <CustomInputField
                    label="Visa Name"
                    span="*"
                    fullWidth
                    value={state.name}
                    name="name"
                    // onChange={(e) => handleStateChange("name", e.target.value)}
                    onChange={(e) => {
                      const input = e.target.value;
                      const validInput = input.replace(
                        /[^a-zA-Z\s\-'.()]/g,
                        ""
                      ); // Allows letters, spaces, hyphens, apostrophes, periods, and parentheses
                      setState({
                        ...state,
                        name: validInput,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <CustomInputAndSelectField
                    options={countryInfo.map((item) => item.country_name)} // Set options from entry type names
                    value={state.from_country}
                    label="From Country"
                    span="*"
                    fullWidth
                    changeCallBack={(e, v) => {
                      console.log(v);
                      const selectedEntryType = countryInfo.find(
                        (item) => item.country_name === v
                      );

                      setState((prevState) => ({
                        ...prevState,
                        from_country: v,
                        from_country_id: selectedEntryType
                          ? selectedEntryType.id
                          : "",
                      }));
                    }}
                    // Handle onChange event
                  />
                </Grid>
                <Grid item xs={2}>
                  <CustomInputAndSelectField
                    options={countryInfo.map((item) => item.country_name)} // Set options from entry type names
                    value={state.to_country}
                    label="To Country"
                    span="*"
                    fullWidth
                    changeCallBack={(e, v) => {
                      console.log(v);
                      const selectedEntryType = countryInfo.find(
                        (item) => item.country_name === v
                      );

                      setState((prevState) => ({
                        ...prevState,
                        to_country: v,
                        to_country_id: selectedEntryType
                          ? selectedEntryType.id
                          : "",
                      }));
                    }}
                    // Handle onChange event
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInputAndSelectField
                    multiple // Enable multiple selection
                    options={countryInfo.map((item) => item.country_name)}
                    value={state.citizenship_country} // Value should be an array for multiple selection
                    label="Is Citizenship Available for country"
                    span="*"
                    fullWidth
                    changeCallBack={(e, values) => {
                      const selectedCountries = values.map((v) => {
                        const selectedEntryType = countryInfo.find(
                          (item) => item.country_name === v
                        );
                        return {
                          name: v,
                          id: selectedEntryType ? selectedEntryType.id : "",
                        };
                      });
                      setState((prevState) => ({
                        ...prevState,
                        citizenship_country: values, // Update the array of selected countries
                        citizenship_countryId: selectedCountries.map(
                          (item) => item.id
                        ),
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <CustomInputField
                    type="file"
                    fullWidth
                    label="Visa Thumbnail"
                    span="*"
                    onChange={(e) => {
                      const imageRef = ref(
                        storage,
                        `/thumbnail+${Math.random()}`
                      );
                      uploadBytes(imageRef, e.target.files[0]).then(() => {
                        getDownloadURL(imageRef)
                          .then((url) => {
                            localStorage.setItem("thumbnail", url);
                            setState({
                              ...state,
                              visa_thumbnail: localStorage.getItem("thumbnail"),
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  {state.visa_thumbnail && (
                    <>
                      <p>Thumbnail image</p>
                      {/* <Avatar
                  variant="rounded"
                  alt="VISA"
                  src={state.visa_thumbnail}
                  style={{
                    margin: "auto",
                  }}
                /> */}
                      <ImageWithPopup
                        src={state.visa_thumbnail}
                        alt="Example Image"
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={7}>
                  <p>
                    Visa Photos
                    <span style={{ color: "red", fontSize: "24px" }}>*</span>
                  </p>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{
                      padding: "4px",
                      borderColor: "rgba(148,158,171,0.32)",
                      boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                      width: "100%",
                      borderRadius: "12px",
                    }}
                    className={classNames(classes.root)}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      style={{
                        marginRight: "1rem",
                        backgroundColor: "#f0f0f0",
                        color: "#27282C",
                        fontWeight: "500",
                        boxShadow: "none",
                      }}
                    >
                      Choose File
                      <input
                        type="file"
                        hidden
                        multiple
                        fullWidth
                        label="Visa Photos"
                        span="*"
                        onChange={async (e) => {
                          const files = Array.from(e.target.files);
                          if (files.length > 0) {
                            setFileName(`${files.length} file(s) chosen`);
                          } else {
                            setFileName("No file chosen");
                          }

                          const newPhotos = [];

                          for (let i = 0; i < files.length; i++) {
                            const file = files[i];
                            const imageRef = ref(
                              storage,
                              `/images+${Math.random()}`
                            );

                            try {
                              await uploadBytes(imageRef, file);
                              const url = await getDownloadURL(imageRef);
                              newPhotos.push(url);
                            } catch (error) {
                              console.error("Error uploading file:", error);
                            }
                          }

                          setState((prevState) => ({
                            ...prevState,
                            visa_photos: [
                              ...prevState.visa_photos,
                              ...newPhotos,
                            ],
                          }));
                        }}
                      />
                    </Button>
                    <span style={{ color: "#27282C", fontWeight: "500" }}>
                      {fileName}
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}></Grid>
                <Grid item xs={12} style={{ display: "flex", gap: "20px" }}>
                  {visaPhotos != null
                    ? visaPhotos.map((el, idx) => (
                        <>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {/* <img src={el} style={{ height: "50px" }} /> */}
                            <ImageWithPopup src={el} alt="Example Image" />
                            <CustomButton
                              variant="contained"
                              color="error"
                              onClick={() => removePhotos(idx, "prev", el)}
                              icon={<FaTimes />}
                            />
                          </div>
                        </>
                      ))
                    : console.log(visaPhotos)}

                  {state.visa_photos != null
                    ? state.visa_photos.map((el, idx) => (
                        <>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {/* <img src={el} style={{ height: "50px" }} /> */}
                            <ImageWithPopup src={el} alt="Example Image" />

                            <CustomButton
                              variant="contained"
                              color="error"
                              onClick={() => removePhotos(idx, "new", el)}
                              icon={<FaTimes />}
                            />
                          </div>
                        </>
                      ))
                    : console.log(state.visa_photos)}
                </Grid>
                {/* <Grid item xs={7}>
            <CustomInputAndSelectField
              options={countryInfo.map((item) => item.country_name)} // Set options from entry type names
              value={state.from_country}
              label="From Country"
              fullWidth
              changeCallBack={(e, v) => {
                console.log(v);
                const selectedEntryType = countryInfo.find(
                  (item) => item.country_name === v
                );

                setState((prevState) => ({
                  ...prevState,
                  from_country: v,
                  from_country_id: selectedEntryType
                    ? selectedEntryType.id
                    : "",
                }));
              }}
              // Handle onChange event
            />
          </Grid> */}
                {/* <Grid item xs={7}>
            <CustomInputAndSelectField
              options={countryInfo.map((item) => item.country_name)} // Set options from entry type names
              value={state.to_country}
              label="To Country"
              fullWidth
              changeCallBack={(e, v) => {
                console.log(v);
                const selectedEntryType = countryInfo.find(
                  (item) => item.country_name === v
                );

                setState((prevState) => ({
                  ...prevState,
                  to_country: v,
                  to_country_id: selectedEntryType ? selectedEntryType.id : "",
                }));
              }}
              // Handle onChange event
            />
          </Grid> */}
                <Grid item xs={12} md={4}>
                  <CustomInputField
                    label="Marketing Price"
                    span="*"
                    fullWidth
                    value={state.marketing_price}
                    onChange={(e) => {
                      const input = e.target.value;
                      const validInput = input
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*)\./g, "$1"); // Remove non-numeric and prevent multiple decimals
                      setState({
                        ...state,
                        marketing_price: validInput,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomInputField
                    label="Receive Time (in hours)"
                    span="*"
                    fullWidth
                    value={state.receive_days}
                    onChange={(e) => {
                      const input = e.target.value;
                      const validInput = input.replace(/[^0-9]/g, ""); // Allow only numbers
                      setState({
                        ...state,
                        receive_days: validInput,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Country Description{" "}
                    <span style={{ color: "red", fontSize: "24px" }}>*</span>
                  </p>
                  <ReactQuill
                    theme="snow"
                    value={state.flight_change_over_description}
                    onChange={(value) =>
                      setState({
                        ...state,
                        flight_change_over_description: value,
                      })
                    }
                    modules={{
                      toolbar: [
                        [{ size: ["small", false, "large", "huge"] }], // Font sizes
                        [{ header: [1, 2, 3, false] }],
                        ["bold", "italic", "underline", "strike"], // Formatting buttons
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ indent: "-1" }, { indent: "+1" }], // Indent buttons
                        [{ align: [] }], // Alignment buttons
                        ["link", "image"], // Link and image buttons
                      ],
                    }}
                    style={{ height: "200px", marginBottom: "19px" }}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                Benefits<span style={{ color: "red", fontSize: "24px" }}>*</span>
                <p />
                <Editor
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  editorState={state.benifits}
                  onEditorStateChange={(e) =>
                    setState({
                      ...state,
                      benifits: e,
                    })
                  }
                />
              </Grid> */}
                {/* <Grid item xs={12} md={7}>
            <CustomInputField
              label="Benefits"
              fullWidth
              value={state.benifits}
              onChange={(e) => handleStateChange("benifits", e.target.value)}
            />
          </Grid> */}
                {/* <Grid item xs={6}>
            <p>RECEIVE TIME</p>
            <TimePicker
              onChange={handleTimeChange}
              value={state.receive_time}
              format="h:mm a" //for 12-hour format with AM/PM
              style={{ width: "0px" }}
            />
          </Grid> */}
                <Grid item xs={7}>
                  <CustomInputField
                    label="Validity Period"
                    span="*"
                    fullWidth
                    value={state.validity_period}
                    onChange={(e) =>
                      handleStateChange("validity_period", e.target.value)
                    }
                    // onChange={(e) => {
                    //   const input = e.target.value;
                    //   const validInput = input.replace(/[^a-zA-Z\s]/g, ""); // Allow only letters
                    //   setState({
                    //     ...state,
                    //     validity_period: validInput,
                    //   });
                    // }}
                  />
                </Grid>{" "}
                <Grid item xs={7}>
                  <CustomInputField
                    label="Validity Description"
                    span="*"
                    fullWidth
                    value={state.validity_description}
                    onChange={(e) => {
                      const input = e.target.value;

                      setState({
                        ...state,
                        validity_description: input,
                      });
                    }}
                  />
                </Grid>{" "}
                <Grid item xs={5}></Grid>
                <Grid item xs={12} md={4}>
                  <CustomInputAndSelectField
                    options={["Yes", "No"]}
                    value={state.is_comingsoon}
                    changeCallBack={(e, v) => {
                      console.log(v);
                      handleStateChange("is_comingsoon", v);
                      // if (v == "Yes") {
                      //   handleStateChange("is_comingsoon", "");
                      // } else {
                      //   handleStateChange("is_comingsoon", "False");
                      // }
                    }}
                    label="Is Available Soon"
                    span="*"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomInputAndSelectField
                    options={["Yes", "No"]}
                    label="Visa Required"
                    span="*"
                    value={state.is_visa_required}
                    changeCallBack={(e, v) => {
                      console.log(v);
                      handleStateChange("is_visa_required", v);
                      // if (v == "Yes") {
                      //   handleStateChange("is_visa_required", "True");
                      // } else {
                      //   handleStateChange("is_visa_required", "False");
                      // }
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5}></Grid>
                <Grid item xs={12} md={4}>
                  <CustomInputAndSelectField
                    options={["Yes", "No"]}
                    value={state.is_visa_issued_on_arriving}
                    changeCallBack={(e, v) => {
                      console.log(v);
                      handleStateChange("is_visa_issued_on_arriving", v);
                      // if (v == "Yes") {
                      //   handleStateChange("is_visa_issued_on_arriving", "True");
                      // } else {
                      //   handleStateChange("is_visa_issued_on_arriving", "False");
                      // }
                    }}
                    label="Visa Issued On Arriving"
                    span="*"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomInputAndSelectField
                    multiple // Enable multiple selection
                    options={visaInformation.map(
                      (item) => item.entry_type_name
                    )} // Map entry type names as options
                    value={state.entry_type} // Pass the selected values array
                    label="Entry Type" // Label for the dropdown
                    span="*" // Required field indicator
                    fullWidth
                    changeCallBack={(e, values) => {
                      // Callback function to handle changes
                      const selectedIds = values
                        .map((selectedValue) => {
                          const selectedItem = visaInformation.find(
                            (item) => item.entry_type_name === selectedValue
                          );
                          return selectedItem ? selectedItem.id : null;
                        })
                        .filter(Boolean); // Filter out any null values

                      setState({
                        ...state,
                        entry_type: values, // Update the selected entry types
                        entry_id: selectedIds, // Update the array of selected entry type IDs
                      });
                    }}
                  />
                </Grid>
                {/* <CustomInputAndSelectField
            //options={visaInformation.map((item) => item.entry_type_name)} // Set options from entry type names
            value={state.entry_type}
            label="Entry type"
            fullWidth
            changeCallBack={(e, v) => {
              console.log(v);
              handleEntryTypeChange(v);
            }}
            // Handle onChange event
          /> */}
                <Grid item xs={5}></Grid>
                <Grid item xs={12} md={4}>
                  <CustomInputField
                    label="Meta Title"
                    span="*"
                    fullWidth
                    value={state.meta_title}
                    name="name"
                    onChange={(e) => {
                      const input = e.target.value;

                      setState({
                        ...state,
                        meta_title: input,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomInputField
                    label="Meta Keywords"
                    span="*"
                    fullWidth
                    value={state.meta_keywords}
                    name="name"
                    onChange={(e) =>
                      handleStateChange("meta_keywords", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={5}></Grid>
                <Grid item xs={12} md={4}>
                  <CustomInputField
                    label="Meta Description"
                    span="*"
                    fullWidth
                    value={state.meta_description}
                    name="name"
                    onChange={(e) =>
                      handleStateChange("meta_description", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomInputAndSelectField
                    options={time_cat.map((item) => item.visa_time)} // Set options from entry type names
                    value={state.visa_time}
                    label="Visa Time Category"
                    span="*"
                    fullWidth
                    changeCallBack={(e, v) => {
                      console.log(v);
                      const selectedEntryType = time_cat.find(
                        (item) => item.visa_time === v
                      );

                      setState((prevState) => ({
                        ...prevState,
                        visa_time: v,
                        visa_timeId: selectedEntryType
                          ? selectedEntryType.id
                          : "",
                      }));
                    }}
                    // Handle onChange event
                  />
                </Grid>
                <div className={classes.contentMain} style={{ width: "100%" }}>
                  <Paper
                    className={classes.paper}
                    style={{ marginLeft: "16px" }}
                  >
                    <Grid
                      container
                      spacing={3}
                      className="content"
                      alignItems={"end"}
                    >
                      {
                        <>
                          {state.fee_info ? (
                            state.fee_info.map((ch, idx) => (
                              <>
                                <Grid item xs={7}>
                                  <CustomInputAndSelectField
                                    options={feeList.map(
                                      (item) => item.fee_name
                                    )} // Set options from entry type names
                                    value={ch.fee_type}
                                    label="Fee Types"
                                    span="*"
                                    fullWidth
                                    changeCallBack={(e, v) => {
                                      const selectedEntryType = feeList.find(
                                        (item) => item.fee_name === v
                                      );
                                      const updatedvar = [...state.fee_info];
                                      updatedvar[idx].fee_type = v;
                                      updatedvar[idx].fee_type_id =
                                        selectedEntryType
                                          ? selectedEntryType.id
                                          : "";
                                      setState({
                                        ...state,
                                        fee_info: updatedvar,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <CustomInputField
                                    label="Fee Amount ($)"
                                    span="*"
                                    fullWidth
                                    value={ch.fee_amount}
                                    name="name"
                                    onChange={(e) => {
                                      const updatedvar = [...state.fee_info];
                                      const input = e.target.value;

                                      // Allow only numbers with up to 2 decimal places
                                      const validInput = input
                                        .replace(/[^0-9.]/g, "")
                                        .replace(/(\..*)\./g, "$1"); // Remove non-numeric and prevent multiple decimals

                                      if (/^\d*\.?\d{0,2}$/.test(validInput)) {
                                        updatedvar[idx].fee_amount = validInput;
                                        setState({
                                          ...state,
                                          fee_info: updatedvar,
                                        });
                                      }
                                    }}
                                  />
                                </Grid>

                                {/* Show Discounted Price field only if fee_type_id is NOT 13 */}
                                {ch.fee_type_id !== 13 && (
                                  <Grid item xs={7}>
                                    <CustomInputField
                                      label="Discounted Price ($)"
                                      fullWidth
                                      value={ch.discounted_price}
                                      name="name"
                                      onChange={(e) => {
                                        const updatedvar = [...state.fee_info];
                                        const input = e.target.value;

                                        // Allow only numbers with up to 2 decimal places
                                        const validInput = input
                                          .replace(/[^0-9.]/g, "")
                                          .replace(/(\..*)\./g, "$1"); // Remove non-numeric and prevent multiple decimals

                                        if (
                                          /^\d*\.?\d{0,2}$/.test(validInput)
                                        ) {
                                          updatedvar[idx].discounted_price =
                                            validInput;
                                          setState({
                                            ...state,
                                            fee_info: updatedvar,
                                          });
                                        }
                                      }}
                                    />
                                  </Grid>
                                )}

                                <Grid item xs={7}>
                                  <CustomInputField
                                    label="Fee Description"
                                    span="*"
                                    fullWidth
                                    value={ch.fee_description}
                                    name="name"
                                    onChange={(e) => {
                                      const updatedvar = [...state.fee_info];
                                      updatedvar[idx].fee_description =
                                        e.target.value;
                                      setState({
                                        ...state,
                                        fee_info: updatedvar,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  style={{
                                    paddingTop: "64px",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  
                                  <span className={classes.actionBtn}>
                                    <CustomButton
                                      variant="contained"
                                      color="error"
                                      onClick={async (e) => {
                                        const newArray = [...state.fee_info];
                                        const fee = newArray[idx];

                                        if (fee.id) {
                                          // Call the delete API function
                                          const success =  deleteVisaFee(
                                            fee.id
                                          );
                                        }

                                        // Remove the item from the array
                                        newArray.splice(idx, 1);
                                        setState({
                                          ...state,
                                          fee_info: newArray,
                                        });
                                      }}
                                      icon={<FaTimes />}
                                    />
                                  </span>
                                </Grid>
                              </>
                            ))
                          ) : (
                            <p></p>
                          )}

                          <Grid item xs={12} md={4}>
                            <CustomInputField
                              label="Visa Pricing ($)"
                              fullWidth
                              value={state.visa_pricing}
                              Inputprops={{ readonly: true }}
                            />
                            <Button
                              style={{ marginTop: "18px" }}
                              variant="contained"
                              fullWidth
                              onClick={() => {
                                setState({
                                  ...state,
                                  fee_info: [
                                    ...state.fee_info,
                                    {
                                      fee_type: "",
                                      fee_amount: "",
                                      fee_description: "",
                                      fee_type_id: "",
                                    },
                                  ],
                                });
                              }}
                            >
                              Add Fee
                            </Button>
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Paper>
                </div>
                <div className={classes.contentMain} style={{ width: "100%" }}>
                  <p>FAQ section</p>
                  <Paper
                    className={classes.paper}
                    style={{ marginLeft: "16px" }}
                  >
                    <Grid
                      container
                      spacing={3}
                      className="content"
                      alignItems={"end"}
                    >
                      {
                        <>
                          {state.faq_info ? (
                            state.faq_info.map((ch, idx) => (
                              <>
                                <Grid item xs={7}>
                                  <CustomInputField
                                    label="Question"
                                    fullWidth
                                    value={ch.question}
                                    name="name"
                                    onChange={(e) => {
                                      const updatedvar = [...state.faq_info];
                                      updatedvar[idx].question = e.target.value;
                                      setState({
                                        ...state,
                                        faq_info: updatedvar,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  Answer
                                  <Editor
                                    label="Answer"
                                    editorState={ch.answer}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    // value={state.country_description}

                                    onEditorStateChange={(e) => {
                                      const updatedvar = [...state.faq_info];
                                      updatedvar[idx].answer = e;
                                      setState({
                                        ...state,
                                        faq_info: updatedvar,
                                      });
                                    }}
                                    // onChange={(e) =>
                                    // }}
                                  />
                                </Grid>
                                <Grid
                                  style={{
                                    paddingTop: "64px",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  {" "}
                                  <span className={classes.actionBtn}>
                                    <CustomButton
                                      variant="contained"
                                      color="error"
                                      onClick={(e) => {
                                        const newArray = [...state.faq_info];
                                        const itemToRemove = newArray[idx];

                                        if (itemToRemove.id !== "") {
                                          // Call your API here
                                          // Example:
                                          axios
                                            .post(
                                              url +
                                                "faqforvisa/deletefaqforvisa",
                                              {
                                                id: itemToRemove.id,
                                              },
                                              {
                                                headers: {
                                                  /* Your headers here */
                                                  "Content-Type":
                                                    "application/json", // Example header
                                                  Authorization: `Bearer ${token}`, // Example authorization header
                                                },
                                              }
                                            )
                                            .then((r) => {
                                              newArray.splice(idx, 1);
                                              setState({
                                                ...state,
                                                faq_info: newArray,
                                              });
                                            })
                                            .catch((error) => {
                                              console.error(
                                                "Error fetching data:",
                                                error
                                              );
                                              // Handle error here
                                            });
                                        } else {
                                          // If id is empty, directly remove the item from the array
                                          newArray.splice(idx, 1);
                                          setState({
                                            ...state,
                                            faq_info: newArray,
                                          });
                                        }
                                      }}
                                      icon={<FaTimes />}
                                    />
                                  </span>
                                </Grid>
                              </>
                            ))
                          ) : (
                            <p></p>
                          )}

                          <Grid item xs={12} md={4}>
                            <Button
                              style={{ marginTop: "18px" }}
                              variant="contained"
                              fullWidth
                              onClick={() => {
                                setState({
                                  ...state,
                                  faq_info: [
                                    ...state.faq_info,
                                    {
                                      question: "",
                                      answer: EditorState.createEmpty(),
                                      id: "",
                                    },
                                  ],
                                });
                              }}
                            >
                              Add FAQ
                            </Button>
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Paper>
                </div>{" "}
                <div className={classes.contentMain} style={{ width: "100%" }}>
                  <p>Partner Section</p>
                  <Paper
                    className={classes.paper}
                    style={{ marginLeft: "16px" }}
                  >
                    <Grid
                      container
                      spacing={3}
                      className="content"
                      alignItems={"end"}
                    >
                      <Grid item xs={7}>
                        <p>Logo</p>
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{
                            padding: "4px",
                            borderColor: "rgba(148,158,171,0.32)",
                            boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                            width: "100%",
                            borderRadius: "12px",
                          }}
                          className={classNames(classes.root)}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "#f0f0f0",
                              color: "#27282C",
                              fontWeight: "500",
                              boxShadow: "none",
                            }}
                          >
                            Choose File
                            <input
                              type="file"
                              hidden
                              multiple
                              fullWidth
                              label="Visa Photos"
                              onChange={async (e) => {
                                const files = Array.from(e.target.files);
                                if (files.length > 0) {
                                  setFileName(`${files.length} file(s) chosen`);
                                } else {
                                  setFileName("No file chosen");
                                }

                                const newPhotos = [];

                                for (let i = 0; i < files.length; i++) {
                                  const file = files[i];
                                  const imageRef = ref(
                                    storage,
                                    `/images+${Math.random()}`
                                  );

                                  try {
                                    await uploadBytes(imageRef, file);
                                    const url = await getDownloadURL(imageRef);
                                    newPhotos.push(url);
                                  } catch (error) {
                                    console.error(
                                      "Error uploading file:",
                                      error
                                    );
                                  }
                                }

                                setState((prevState) => ({
                                  ...prevState,
                                  logo: [...prevState.logo, ...newPhotos],
                                }));
                              }}
                            />
                          </Button>
                          <span style={{ color: "#27282C", fontWeight: "500" }}>
                            {fileName}
                          </span>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}></Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", gap: "20px" }}
                      >
                        {logoPhotos != null
                          ? logoPhotos.map((el, idx) => (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {/* <img src={el} style={{ height: "50px" }} /> */}
                                  <ImageWithPopup
                                    src={el}
                                    alt="Example Image"
                                  />

                                  <CustomButton
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                      removelogoPhotos(idx, "prev")
                                    }
                                    icon={<FaTimes />}
                                  />
                                </div>
                              </>
                            ))
                          : console.log(logoPhotos)}

                        {state.logo != null
                          ? state.logo.map((el, idx) => (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {/* <img src={el} style={{ height: "50px" }} /> */}
                                  <ImageWithPopup
                                    src={el}
                                    alt="Example Image"
                                  />

                                  <CustomButton
                                    variant="contained"
                                    color="error"
                                    onClick={() => removelogoPhotos(idx, "new")}
                                    icon={<FaTimes />}
                                  />
                                </div>
                              </>
                            ))
                          : console.log(state.logo)}
                      </Grid>
                    </Grid>
                  </Paper>
                </div>{" "}
                <div className={classes.contentMain} style={{ width: "100%" }}>
                  <p>Customer Testimonial Section</p>
                  <Paper
                    className={classes.paper}
                    style={{ marginLeft: "16px" }}
                  >
                    <Grid
                      container
                      spacing={3}
                      className="content"
                      alignItems={"end"}
                    >
                      {
                        <>
                          {state.customer_testimonial ? (
                            state.customer_testimonial.map((ch, idx) => (
                              <>
                                <Grid item xs={7}>
                                  <CustomInputField
                                    label="Name"
                                    fullWidth
                                    value={ch.name}
                                    name="name"
                                    onChange={(e) => {
                                      const updatedvar = [
                                        ...state.customer_testimonial,
                                      ];
                                      const input = e.target.value;
                                      const validInput = input.replace(
                                        /[^a-zA-Z\s]/g,
                                        ""
                                      );
                                      updatedvar[idx].name = validInput;
                                      setState({
                                        ...state,
                                        customer_testimonial: updatedvar,
                                      });
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={6}>
                                  <CustomInputField
                                    fullWidth
                                    label="Customer Photo"
                                    type="file"
                                    onChange={(e) => {
                                      const imageRef = ref(
                                        storage,
                                        `/customers+${Math.random()}`
                                      );
                                      uploadBytes(
                                        imageRef,
                                        e.target.files[0]
                                      ).then(() => {
                                        getDownloadURL(imageRef)
                                          .then((url) => {
                                            // link = url;
                                            //console.log("Image URL:", url);

                                            localStorage.setItem(
                                              "customer",
                                              url
                                            );
                                            const updatedvar = [
                                              ...state.customer_testimonial,
                                            ];
                                            updatedvar[idx].image =
                                              localStorage.getItem("customer");
                                            setState({
                                              ...state,
                                              customer_testimonial: updatedvar,
                                            });
                                            // handleSaveVisaCategory();
                                            // setState({
                                            //   ...state,
                                            //   image:
                                            //     localStorage.getItem("customer"),
                                            // });
                                          })
                                          .catch((error) => {
                                            console.log(error);
                                          });
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  {ch.image && (
                                    <>
                                      <p> image</p>
                                      {/* <Avatar
                                  variant="rounded"
                                  alt="VISA"
                                  src={ch.image}
                                  style={{
                                    margin: "auto",
                                  }}
                                /> */}
                                      <ImageWithPopup
                                        src={ch.image}
                                        alt="Example Image"
                                      />
                                    </>
                                  )}
                                </Grid>
                                <Grid item xs={7}>
                                  {/* <CustomInputField
                              type="date"
                              label="Date"
                              fullWidth
                              value={ch.date}
                              name="name"
                              onChange={(e) => {
                                const updatedvar = [
                                  ...state.customer_testimonial,
                                ];
                                updatedvar[idx].date = e.target.value;
                                setState({
                                  ...state,
                                  customer_testimonial: updatedvar,
                                });
                              }}
                            /> */}
                                  <p>Date</p>
                                  <input
                                    required=""
                                    style={{
                                      padding: "10px",
                                      // borderColor: "rgba(148,158,171,0.32)",
                                      boxShadow:
                                        "1px 3px 10px rgba(0,0,0,0.17)",
                                      width: "100%",
                                      borderRadius: "10px",
                                      border: "none",
                                      marginTop: "20px",
                                    }}
                                    type="date"
                                    value={ch.date}
                                    onChange={(e) => {
                                      const updatedvar = [
                                        ...state.customer_testimonial,
                                      ];
                                      updatedvar[idx].date = e.target.value;
                                      setState({
                                        ...state,
                                        customer_testimonial: updatedvar,
                                      });
                                    }}
                                    max={new Date().toISOString().split("T")[0]}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <CustomInputField
                                    fullWidth
                                    label=" Testimonial Title"
                                    value={ch.title}
                                    name="name"
                                    onChange={(e) => {
                                      const updatedvar = [
                                        ...state.customer_testimonial,
                                      ];

                                      const input = e.target.value;

                                      updatedvar[idx].title = input;
                                      setState({
                                        ...state,
                                        customer_testimonial: updatedvar,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <CustomInputField
                                    fullWidth
                                    label="Location"
                                    value={ch.location}
                                    name="name"
                                    onChange={(e) => {
                                      const updatedvar = [
                                        ...state.customer_testimonial,
                                      ];
                                      const input = e.target.value;
                                      const validInput = input.replace(
                                        /[^a-zA-Z\s]/g,
                                        ""
                                      );
                                      updatedvar[idx].location = validInput;
                                      setState({
                                        ...state,
                                        customer_testimonial: updatedvar,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <CustomInputField
                                    fullWidth
                                    type="number"
                                    label="Rating"
                                    value={ch.rating}
                                    name="name"
                                    onChange={(e) => {
                                      const updatedvar = [
                                        ...state.customer_testimonial,
                                      ];
                                      updatedvar[idx].rating = e.target.value;
                                      setState({
                                        ...state,
                                        customer_testimonial: updatedvar,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  Description
                                  <Editor
                                    label="Answer"
                                    editorState={ch.description}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    // value={state.country_description}

                                    onEditorStateChange={(e) => {
                                      const updatedvar = [
                                        ...state.customer_testimonial,
                                      ];
                                      updatedvar[idx].description = e;
                                      setState({
                                        ...state,
                                        customer_testimonial: updatedvar,
                                      });
                                    }}
                                    // onChange={(e) =>
                                    // }}
                                  />
                                </Grid>
                                <Grid
                                  style={{
                                    paddingTop: "64px",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  {" "}
                                  <span className={classes.actionBtn}>
                                    <CustomButton
                                      variant="contained"
                                      color="error"
                                      onClick={(e) => {
                                        const newArray = [
                                          ...state.customer_testimonial,
                                        ];
                                        const itemToRemove = newArray[idx];

                                        if (itemToRemove.id !== "") {
                                          // Call your API here
                                          // Example:
                                          axios
                                            .post(
                                              url +
                                                "customertestimonial/deletecustomertestimonial",
                                              {
                                                id: itemToRemove.id,
                                              },
                                              {
                                                headers: {
                                                  /* Your headers here */
                                                  "Content-Type":
                                                    "application/json", // Example header
                                                  Authorization: `Bearer ${token}`, // Example authorization header
                                                },
                                              }
                                            )
                                            .then((r) => {
                                              newArray.splice(idx, 1);
                                              setState({
                                                ...state,
                                                customer_testimonial: newArray,
                                              });
                                            })
                                            .catch((error) => {
                                              console.error(
                                                "Error fetching data:",
                                                error
                                              );
                                              // Handle error here
                                            });
                                        } else {
                                          // If id is empty, directly remove the item from the array
                                          newArray.splice(idx, 1);
                                          setState({
                                            ...state,
                                            customer_testimonial: newArray,
                                          });
                                        }
                                      }}
                                      icon={<FaTimes />}
                                    />
                                  </span>
                                </Grid>
                              </>
                            ))
                          ) : (
                            <p></p>
                          )}

                          <Grid item xs={12} md={4}>
                            <Button
                              style={{ marginTop: "18px" }}
                              variant="contained"
                              fullWidth
                              onClick={() => {
                                setState({
                                  ...state,
                                  customer_testimonial: [
                                    ...state.customer_testimonial,
                                    {
                                      id: "",
                                      name: "",
                                      image: "",
                                      description: EditorState.createEmpty(),
                                      date: "",
                                      location: "",
                                      title: "",
                                      rating: "",
                                    },
                                  ],
                                });
                              }}
                            >
                              Add Testimonial
                            </Button>
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Paper>
                </div>{" "}
                <div className={classes.contentMain} style={{ width: "100%" }}>
                  <p>Document Section</p>
                  <Paper
                    className={classes.paper}
                    style={{ marginLeft: "16px" }}
                  >
                    <Grid
                      container
                      spacing={3}
                      className="content"
                      alignItems={"end"}
                    >
                      {
                        <>
                          {state.docs ? (
                            state.docs.map((ch, idx) => (
                              <>
                                <Grid item xs={7}>
                                  <CustomInputField
                                    label="Document Title"
                                    fullWidth
                                    value={ch.docs_title}
                                    name="name"
                                    onChange={(e) => {
                                      const updatedvar = [...state.docs];
                                      const input = e.target.value;
                                      const validInput = input;
                                      updatedvar[idx].docs_title = validInput;
                                      setState({
                                        ...state,
                                        docs: updatedvar,
                                      });
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={6}>
                                  <CustomInputField
                                    fullWidth
                                    label="Document Icon"
                                    type="file"
                                    onChange={(e) => {
                                      const imageRef = ref(
                                        storage,
                                        `/customers+${Math.random()}`
                                      );
                                      uploadBytes(
                                        imageRef,
                                        e.target.files[0]
                                      ).then(() => {
                                        getDownloadURL(imageRef)
                                          .then((url) => {
                                            // link = url;
                                            //console.log("Image URL:", url);
                                            const updatedvar = [...state.docs];
                                            updatedvar[idx].docs_icon = url;
                                            setState({
                                              ...state,
                                              docs: updatedvar,
                                            });
                                            // handleSaveVisaCategory();
                                            // setState({
                                            //   ...state,
                                            //   image:
                                            //     localStorage.getItem("customer"),
                                            // });
                                          })
                                          .catch((error) => {
                                            console.log(error);
                                          });
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  {ch.docs_icon && (
                                    <>
                                      <p> image</p>
                                      {/* <Avatar
                                  variant="rounded"
                                  alt="VISA"
                                  src={ch.docs_icon}
                                  style={{
                                    margin: "auto",
                                  }}
                                /> */}
                                      <ImageWithPopup
                                        src={ch.docs_icon}
                                        alt="Example Image"
                                      />
                                    </>
                                  )}
                                </Grid>
                                <Grid
                                  style={{
                                    paddingTop: "64px",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  {" "}
                                  <span className={classes.actionBtn}>
                                    <CustomButton
                                      variant="contained"
                                      color="error"
                                      onClick={(e) => {
                                        const newArray = [...state.docs];
                                        const itemToRemove = newArray[idx];

                                        if (itemToRemove.docs_id) {
                                          // Call your API here
                                          // Example:
                                          axios
                                            .post(
                                              url +
                                                "documentrequired/deletedocumentrequired",
                                              {
                                                id: itemToRemove.docs_id,
                                              },
                                              {
                                                headers: {
                                                  "Content-Type":
                                                    "application/json",
                                                  Authorization: `Bearer ${token}`,
                                                },
                                              }
                                            )
                                            .then((response) => {
                                              console.log(
                                                "API response:",
                                                response.data
                                              );
                                              // Remove item from the array
                                              newArray.splice(idx, 1);
                                              // Update state
                                              setState({
                                                ...state,
                                                docs: newArray,
                                              });
                                              console.log(
                                                "Updated state:",
                                                newArray
                                              );
                                            })
                                            .catch((error) => {
                                              console.error(
                                                "Error deleting document:",
                                                error
                                              );
                                            });
                                        } else {
                                          // If id is empty, directly remove the item from the array
                                          newArray.splice(idx, 1);
                                          // Update state
                                          setState({
                                            ...state,
                                            docs: newArray,
                                          });
                                          console.log(
                                            "Updated state:",
                                            newArray
                                          );
                                        }
                                      }}
                                      icon={<FaTimes />}
                                    />
                                  </span>
                                </Grid>
                              </>
                            ))
                          ) : (
                            <p></p>
                          )}

                          <Grid item xs={12} md={3}>
                            <Button
                              style={{ marginTop: "18px" }}
                              variant="contained"
                              fullWidth
                              onClick={() => {
                                setState({
                                  ...state,
                                  docs: [
                                    ...state.docs,
                                    {
                                      docs_icon: "",
                                      docs_title: "",
                                      docs_id: "",
                                    },
                                  ],
                                });
                              }}
                            >
                              Add Document
                            </Button>
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Paper>
                </div>{" "}
                <div className={classes.contentMain} style={{ width: "100%" }}>
                  <p>Immigration Section</p>
                  <Paper
                    className={classes.paper}
                    style={{ marginLeft: "16px" }}
                  >
                    <Grid
                      container
                      spacing={3}
                      className="content"
                      alignItems={"end"}
                    >
                      {
                        <>
                          {state.immigration ? (
                            state.immigration.map((ch, idx) => (
                              <>
                                <Grid item xs={7}>
                                  <CustomInputAndSelectField
                                    options={[
                                      "Immigration",
                                      "VisaRoot officer",
                                    ]}
                                    value={
                                      ch.type == 1
                                        ? "Immigration"
                                        : ch.type == 2
                                        ? "VisaRoot officer"
                                        : ""
                                    }
                                    changeCallBack={(e, v) => {
                                      const updatedvar = [...state.immigration];

                                      if (v == "Immigration") {
                                        updatedvar[idx].type = 1;
                                        setState({
                                          ...state,
                                          immigration: updatedvar,
                                        });
                                      }
                                      if (v == "VisaRoot officer") {
                                        updatedvar[idx].type = 2;
                                        setState({
                                          ...state,
                                          immigration: updatedvar,
                                        });
                                      }
                                    }}
                                    label="Officer Type"
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <CustomInputField
                                    fullWidth
                                    label="Officer Position"
                                    value={ch.position}
                                    onChange={(e) => {
                                      const updatedvar = [...state.immigration];
                                      const input = e.target.value;
                                      const validInput = input.replace(
                                        /[^a-zA-Z\s]/g,
                                        ""
                                      );
                                      const trimmedInput = validInput.slice(
                                        0,
                                        50
                                      );
                                      updatedvar[idx].position = trimmedInput;
                                      setState({
                                        ...state,
                                        immigration: updatedvar,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <CustomInputField
                                    fullWidth
                                    label="Officer Name"
                                    value={ch.name}
                                    onChange={(e) => {
                                      const updatedvar = [...state.immigration];
                                      const input = e.target.value;
                                      const validInput = input.replace(
                                        /[^a-zA-Z\s]/g,
                                        ""
                                      );
                                      const trimmedInput = validInput.slice(
                                        0,
                                        50
                                      );
                                      updatedvar[idx].name = trimmedInput;
                                      setState({
                                        ...state,
                                        immigration: updatedvar,
                                      });
                                    }}
                                    // onChange={(e) => {
                                    //   const input = e.target.value;
                                    //   // Remove non-alphabetic characters
                                    //   const validInput = input.replace(
                                    //     /[^a-zA-Z\s]/g,
                                    //     ""
                                    //   );
                                    //   // Ensure input does not exceed 50 characters
                                    //   const trimmedInput = validInput.slice(0, 50);
                                    //   setState({
                                    //     ...state,
                                    //     officer_name: trimmedInput,
                                    //   });
                                    // }}
                                  />
                                </Grid>

                                <Grid item xs={7}>
                                  <CustomInputField
                                    fullWidth
                                    label="Officer Photo"
                                    type="file"
                                    onChange={(e) => {
                                      const imageRef = ref(
                                        storage,
                                        `/officers+${Math.random()}`
                                      );
                                      uploadBytes(
                                        imageRef,
                                        e.target.files[0]
                                      ).then(() => {
                                        getDownloadURL(imageRef)
                                          .then((url) => {
                                            // link = url;
                                            //console.log("Image URL:", url);
                                            // handleSaveVisaCategory();
                                            const updatedvar = [
                                              ...state.immigration,
                                            ];
                                            updatedvar[idx].image = url;
                                            setState({
                                              ...state,
                                              immigration: updatedvar,
                                            });
                                          })
                                          .catch((error) => {
                                            console.log(error);
                                          });
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  {ch.image && (
                                    <>
                                      <p> image</p>
                                      {/* <Avatar
                                  variant="rounded"
                                  alt="VISA"
                                  src={ch.image}
                                  style={{ 
                                    margin: "auto",
                                  }}
                                /> */}
                                      <ImageWithPopup
                                        src={ch.image}
                                        alt="Example Image"
                                      />
                                    </>
                                  )}
                                </Grid>
                                <Grid
                                  style={{
                                    paddingTop: "64px",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  {" "}
                                  <span className={classes.actionBtn}>
                                    <CustomButton
                                      variant="contained"
                                      color="error"
                                      onClick={(e) => {
                                        const newArray = [...state.immigration];
                                        const itemToRemove = newArray[idx];

                                        if (itemToRemove.id) {
                                          // Call your API here
                                          // Example:
                                          axios
                                            .post(
                                              url +
                                                "immigrationofficerlist/deleteimmigrationofficerlist",
                                              {
                                                id: itemToRemove.id,
                                              },
                                              {
                                                headers: {
                                                  "Content-Type":
                                                    "application/json",
                                                  Authorization: `Bearer ${token}`,
                                                },
                                              }
                                            )
                                            .then((response) => {
                                              console.log(
                                                "API response:",
                                                response.data
                                              );
                                              // Remove item from the array
                                              newArray.splice(idx, 1);
                                              // Update state
                                              setState({
                                                ...state,
                                                immigration: newArray,
                                              });
                                              console.log(
                                                "Updated state:",
                                                newArray
                                              );
                                            })
                                            .catch((error) => {
                                              console.error(
                                                "Error deleting document:",
                                                error
                                              );
                                            });
                                        } else {
                                          // If id is empty, directly remove the item from the array
                                          newArray.splice(idx, 1);
                                          // Update state
                                          setState({
                                            ...state,
                                            immigration: newArray,
                                          });
                                          console.log(
                                            "Updated state:",
                                            newArray
                                          );
                                        }
                                      }}
                                      icon={<FaTimes />}
                                    />
                                  </span>
                                </Grid>
                              </>
                            ))
                          ) : (
                            <p></p>
                          )}

                          <Grid item xs={12} md={4}>
                            <Button
                              style={{ marginTop: "18px" }}
                              variant="contained"
                              fullWidth
                              onClick={() => {
                                setState({
                                  ...state,
                                  immigration: [
                                    ...state.immigration,
                                    {
                                      position: "",
                                      image: "",
                                      name: "",
                                      type: "",
                                      id: "",
                                    },
                                  ],
                                });
                              }}
                            >
                              Add Immigration
                            </Button>
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Paper>
                </div>{" "}
                <div className={classes.contentMain} style={{ width: "100%" }}>
                  <p>Additional Question</p>
                  <Paper
                    className={classes.paper}
                    style={{ marginLeft: "16px" }}
                  >
                    <Grid
                      container
                      spacing={3}
                      className="content"
                      alignItems={"end"}
                    >
                      {state.question_info ? (
                        state.question_info.map((ch, idx) => (
                          <>
                            <Grid item xs={12}>
                              <CustomInputAndSelectField
                                options={ch.type_list.map((item) => item)} // Set options from entry type names
                                value={ch.question_type}
                                label="Question Type"
                                fullWidth
                                changeCallBack={(e, v) => {
                                  console.log(ch);
                                  const idxSelect = ch.type_list.indexOf(v);
                                  setState((prevState) => {
                                    const newQuestionInfo =
                                      prevState.question_info.map(
                                        (info, index) => {
                                          if (index === idx) {
                                            let updatedInfo = {};
                                            const multipleChoiceTypes = [
                                              "Drop Down",
                                              "Check box",
                                              "Radio",
                                            ];
                                            const selectedType = idxSelect + 1;
                                            const needsChoices =
                                              multipleChoiceTypes.includes(v) ||
                                              [7, 8, 9].includes(selectedType);

                                            if (needsChoices) {
                                              updatedInfo = {
                                                ...info,
                                                type_question: "multiple",
                                                question_type: v,
                                                question_type_id: selectedType,
                                                choice:
                                                  info.choice.length > 0
                                                    ? info.choice
                                                    : [{ choice_title: "" }],
                                              };
                                            } else {
                                              updatedInfo = {
                                                ...info,
                                                type_question: "",
                                                question_type: v,
                                                question_type_id: selectedType,
                                                choice: info.choice,
                                              };
                                            }

                                            return updatedInfo;
                                          }
                                          return info;
                                        }
                                      );

                                    return {
                                      ...prevState,
                                      question_info: newQuestionInfo,
                                    };
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <CustomInputField
                                label="Question"
                                fullWidth
                                value={ch.name}
                                name="name"
                                onChange={(e) => {
                                  const newQuestionInfo = [
                                    ...state.question_info,
                                  ];
                                  const input = e.target.value;
                                  const validInput = input.replace(
                                    /[^a-zA-Z\s]/g,
                                    ""
                                  );
                                  const trimmedInput = validInput.slice(0, 70);
                                  newQuestionInfo[idx].name = trimmedInput;
                                  setState({
                                    ...state,
                                    question_info: newQuestionInfo,
                                  });
                                }}
                                // onChange={(e) => {
                                //   const updatedvar = [...state.immigration];
                                //   const input = e.target.value;
                                //   const validInput = input.replace(
                                //     /[^a-zA-Z\s]/g,
                                //     ""
                                //   );
                                //   const trimmedInput = validInput.slice(0, 50);
                                //   updatedvar[idx].name = trimmedInput;
                                //   setState({
                                //     ...state,
                                //     immigration: updatedvar,
                                //   });
                                // }}
                              />
                            </Grid>

                            {ch.type_question ? (
                              <>
                                {ch.choice.map((ch2, idx2) => (
                                  <>
                                    <Grid item xs={7}>
                                      <CustomInputField
                                        label="Choice"
                                        fullWidth
                                        value={ch2.choice_title}
                                        name="name"
                                        onChange={(e) => {
                                          const newQuestionInfo = [
                                            ...state.question_info,
                                          ];
                                          newQuestionInfo[idx].choice[
                                            idx2
                                          ].choice_title = e.target.value;
                                          setState({
                                            ...state,
                                            question_info: newQuestionInfo,
                                          });
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      style={{
                                        paddingTop: "64px",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      <span className={classes.actionBtn}>
                                        <CustomButton
                                          variant="contained"
                                          color="error"
                                          onClick={() => {
                                            const newQuestionInfo = [
                                              ...state.question_info,
                                            ];
                                            newQuestionInfo[idx].choice.splice(
                                              idx2,
                                              1
                                            );
                                            setState({
                                              ...state,
                                              question_info: newQuestionInfo,
                                            });
                                          }}
                                          icon={<FaTimes />}
                                        />
                                      </span>
                                    </Grid>
                                  </>
                                ))}

                                <Grid item xs={12} md={4}>
                                  <Button
                                    style={{ marginTop: "18px" }}
                                    variant="contained"
                                    fullWidth
                                    onClick={() => {
                                      const newQuestionInfo = [
                                        ...state.question_info,
                                      ];
                                      newQuestionInfo[idx].choice.push({
                                        choice_title: "",
                                      });
                                      setState({
                                        ...state,
                                        question_info: newQuestionInfo,
                                      });
                                    }}
                                  >
                                    Add Choice
                                  </Button>
                                </Grid>
                              </>
                            ) : (
                              <p></p>
                            )}
                            <Grid item xs={3}>
                              <p>Required</p>
                              <ToggleButtonGroup
                                color="warning"
                                value={ch.required ? "True" : "False"}
                                exclusive
                                onChange={(e, newAlignment) => {
                                  const newQuestionInfo = [
                                    ...state.question_info,
                                  ];
                                  newQuestionInfo[idx].required =
                                    newAlignment === "True";
                                  setState({
                                    ...state,
                                    question_info: newQuestionInfo,
                                  });
                                }}
                                aria-label="Required"
                              >
                                <ToggleButton
                                  value="True"
                                  style={{ color: "black" }}
                                >
                                  Yes
                                </ToggleButton>
                                <ToggleButton
                                  value="False"
                                  style={{ color: "black" }}
                                >
                                  No
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Grid>

                            <Grid
                              style={{
                                paddingTop: "64px",
                                paddingLeft: "20px",
                              }}
                            >
                              {" "}
                              <span className={classes.actionBtn}>
                                <CustomButton
                                  variant="contained"
                                  color="error"
                                  onClick={() => {
                                    if (ch.id) {
                                      try {
                                        const loginHeaders = new Headers();
                                        loginHeaders.append(
                                          "Content-Type",
                                          "application/json"
                                        );

                                        // Assuming you have an authorization token stored in localStorage
                                        const authToken =
                                          localStorage.getItem("tkn");
                                        if (authToken) {
                                          loginHeaders.append(
                                            "Authorization",
                                            `Bearer ${authToken}`
                                          );
                                        }
                                        const data = { id: ch.id };
                                        const requestOptions = {
                                          method: "POST",
                                          headers: loginHeaders,
                                          body: JSON.stringify(data),
                                        };
                                        fetch(
                                          url + "question/deletequestion",
                                          requestOptions
                                        )
                                          .then((res) => res.json())
                                          .then((actualData) => {
                                            console.log(actualData);
                                            // After successful deletion, update the state
                                            const newArray = [
                                              ...state.question_info,
                                            ];
                                            newArray.splice(idx, 1);
                                            setState({
                                              ...state,
                                              question_info: newArray,
                                            });
                                          })
                                          .catch((err) => {
                                            console.log(err);
                                          });
                                      } catch (err) {
                                        console.log(err);
                                      }
                                    } else {
                                      // If id is empty, directly remove the item from the array
                                      const newArray = [...state.question_info];
                                      newArray.splice(idx, 1);
                                      setState({
                                        ...state,
                                        question_info: newArray,
                                      });
                                    }
                                  }}
                                  icon={<FaTimes />}
                                />
                              </span>
                            </Grid>
                          </>
                        ))
                      ) : (
                        <p></p>
                      )}

                      <Grid item xs={12} md={4}>
                        <Button
                          style={{ marginTop: "18px" }}
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            setState({
                              ...state,
                              question_info: [
                                ...state.question_info,
                                {
                                  question_type: "",
                                  question_type_id: "",
                                  type_question: "",
                                  type_list: [
                                    "text input",
                                    "Number",
                                    "Upload",
                                    "Date Picker",
                                    "Toggle",
                                    "Text Area",
                                    "Drop Down",
                                    "Check box",
                                    "Radio",
                                  ],
                                  name: "",
                                  choice: [],
                                },
                              ],
                            });
                          }}
                        >
                          Add Question
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
                <div className={classes.contentMain} style={{ width: "100%" }}>
                  <p>Price Compare Section</p>
                  <Paper
                    className={classes.paper}
                    style={{ marginLeft: "16px" }}
                  >
                    <Grid
                      container
                      spacing={3}
                      className="content"
                      alignItems={"end"}
                    >
                      {
                        <>
                          {state.priceCmp_info ? (
                            state.priceCmp_info.map((ch, idx) => (
                              <>
                                <Grid item xs={7}>
                                  <CustomInputField
                                    type="provided_name"
                                    label="Provider Name"
                                    fullWidth
                                    value={ch.provided_name}
                                    onChange={(e) => {
                                      const updatedvar = [
                                        ...state.priceCmp_info,
                                      ];
                                      const input = e.target.value;
                                      // Remove non-alphabetic characters
                                      const validInput = input.replace(
                                        /[^a-zA-Z\s]/g,
                                        ""
                                      );
                                      // Ensure input does not exceed 50 characters
                                      const trimmedInput = validInput.slice(
                                        0,
                                        50
                                      );

                                      updatedvar[idx].provided_name =
                                        trimmedInput;
                                      setState({
                                        ...state,
                                        priceCmp_info: updatedvar,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <CustomInputField
                                    type="number"
                                    label="Price ($)"
                                    fullWidth
                                    value={ch.price}
                                    onChange={(e) => {
                                      const updatedvar = [
                                        ...state.priceCmp_info,
                                      ];
                                      const input = e.target.value;
                                      // Remove non-digit characters
                                      const validInput = input.replace(
                                        /\D/g,
                                        ""
                                      );
                                      // Ensure input does not exceed 15 digits
                                      const trimmedInput = validInput.slice(
                                        0,
                                        15
                                      );
                                      updatedvar[idx].price = trimmedInput;
                                      setState({
                                        ...state,
                                        priceCmp_info: updatedvar,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  style={{
                                    paddingTop: "64px",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  {" "}
                                  <span className={classes.actionBtn}>
                                    <CustomButton
                                      variant="contained"
                                      color="error"
                                      onClick={(e) => {
                                        const newArray = [
                                          ...state.priceCmp_info,
                                        ];
                                        const itemToRemove = newArray[idx];

                                        if (itemToRemove.id !== "") {
                                          // Call your API here
                                          // Example:
                                          axios
                                            .post(
                                              url +
                                                "pricecompair/deletepricecompair",
                                              {
                                                id: itemToRemove.id,
                                              },
                                              {
                                                headers: {
                                                  /* Your headers here */
                                                  "Content-Type":
                                                    "application/json", // Example header
                                                  Authorization: `Bearer ${token}`, // Example authorization header
                                                },
                                              }
                                            )
                                            .then((r) => {
                                              newArray.splice(idx, 1);
                                              setState({
                                                ...state,
                                                priceCmp_info: newArray,
                                              });
                                            })
                                            .catch((error) => {
                                              console.error(
                                                "Error fetching data:",
                                                error
                                              );
                                              // Handle error here
                                            });
                                        } else {
                                          // If id is empty, directly remove the item from the array
                                          newArray.splice(idx, 1);
                                          setState({
                                            ...state,
                                            priceCmp_info: newArray,
                                          });
                                        }
                                      }}
                                      icon={<FaTimes />}
                                    />
                                  </span>
                                </Grid>
                              </>
                            ))
                          ) : (
                            <p></p>
                          )}

                          <Grid item xs={12} md={4}>
                            <Button
                              style={{ marginTop: "18px" }}
                              variant="contained"
                              fullWidth
                              onClick={() => {
                                setState({
                                  ...state,
                                  priceCmp_info: [
                                    ...state.priceCmp_info,
                                    {
                                      price: "",
                                      provided_name: "",
                                      id: "",
                                    },
                                  ],
                                });
                              }}
                            >
                              Add Provider
                            </Button>
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Paper>
                </div>{" "}
                {/* <div className={classes.contentMain} style={{ width: "100%" }}>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              ></Grid>
            </Paper>
          </div> */}
                <Grid item xs={12}>
                  {state.isUpdate ? (
                    <Button
                      variant="contained"
                      fullWidth
                      color="warning"
                      onClick={(e) => {
                        handleUpdateInfo(state.visaId);
                      }}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={(e) => {
                        handleCreateInfo();
                      }}
                    >
                      Create
                    </Button>
                  )}
                </Grid>
                <ToastContainer />
              </Grid>
            </div>
          </div>
        </>
      )}
    </>
  );
}
