import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CountryTable from "../../components/CountryTable/Index";
import slugify from "react-slugify";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { useNavigate } from "react-router";
import { MdLogout } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import url from "../../API";
import DashboardHeader from "../../components/DashHeader/Index";
import ImageWithPopup from "../../components/ImagewithPop/Index";
export default function BlogSection() {
  const navigate = useNavigate();
  const classes = offerStyles();
  const token = localStorage.getItem("token");
  const formData = new FormData();
  const location = useLocation();
  const { updateBlog } = location.state || {};
  const [state, setState] = React.useState({
    title: "",
    thumbnail_image: "",
    main_image: "",
    description: EditorState.createEmpty(),
    written_by: "",
    date: "",
    blogCat_id: "",
    blog_cat: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    isUpdate: false,
  });
  const [bloglist, setbloglist] = React.useState([]);
  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "blogcategory/getallblogcategory",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setbloglist(actualData.BlogCategory);
    } catch (e) {
      console.log(e);
    }
  }
  React.useEffect(() => {
    table();
    // console.log(state);
    if (updateBlog) {
      // const updateobj = JSON.parse(localStorage.getItem("updateBlog"));
      // console.log(updateobj.id);
      const updateobj = updateBlog;
      setState({
        thumbnail_image: updateobj.thumbnail_image,
        main_image: updateobj.main_image,
        description: EditorState.createWithContent(
          convertFromRaw(JSON.parse(updateobj.description))
        ),
        written_by: updateobj.written_by,
        title: updateobj.title,
        blog_cat: updateobj.categories[0].category_name,
        blogCat_id: updateobj.categories[0].id,
        meta_title: updateobj.meta_title,
        meta_description: updateobj.meta_description,
        meta_keywords: updateobj.meta_keywords,
        date: updateobj.date,
        isUpdate: true,
        visaId: updateobj.id,
      });
      // localStorage.removeItem("updateId");
      // handleUpdateVisaCategory(updateobj.id);
    }
  }, []);

  const handleSaveVisaCategory = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        thumbnail_image: state.thumbnail_image,
        main_image: state.main_image,
        description: JSON.stringify(
          convertToRaw(state.description.getCurrentContent())
        ),
        written_by: state.written_by,
        title: state.title,
        date: state.date,
        blog_category_id: state.blogCat_id,
        meta_title: state.meta_title,
        meta_description: state.meta_description,
        meta_keywords: state.meta_keywords,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(url + "blog/createblog", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      // setbloglist(actualData.Country);
      if (actualData.status == 201) {
        // table();
        setState({
          title: "",
          thumbnail_image: "",
          main_image: "",
          description: EditorState.createEmpty(),
          written_by: "",
          date: "",
          blogCat_id: "",
          blog_cat: "",
          meta_title: "",
          meta_description: "",
          meta_keywords: "",
          isUpdate: false,
        });
        localStorage.setItem("createBlog", 1);
        navigate("/bloglist");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateVisaCategory = async (id) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        thumbnail_image: state.thumbnail_image,
        main_image: state.main_image,
        description: JSON.stringify(
          convertToRaw(state.description.getCurrentContent())
        ),
        written_by: state.written_by,
        title: state.title,
        date: state.date,
        blog_category_id: state.blogCat_id,
        meta_title: state.meta_title,
        meta_description: state.meta_description,
        meta_keywords: state.meta_keywords,
        id: id,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(url + "blog/updateblog", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        localStorage.removeItem("updateBlog");
        setState({
          title: "",
          thumbnail_image: "",
          main_image: "",
          description: EditorState.createEmpty(),
          written_by: "",
          date: "",
          blogCat_id: "",
          blog_cat: "",
          meta_title: "",
          meta_description: "",
          meta_keywords: "",
          isUpdate: false,
        });
        localStorage.setItem("updateBlogsus", 1);
        navigate("/bloglist");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>Blog</p>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={4}>
                <CustomInputField
                  label="Thumbnail Image"
                  type="File"
                  fullWidth
                  onChange={(e) => {
                    const imageRef = ref(storage, `/images+${Math.random()}`);
                    uploadBytes(imageRef, e.target.files[0]).then(() => {
                      getDownloadURL(imageRef)
                        .then((url) => {
                          // localStorage.setItem("blogt", url);

                          setState({
                            ...state,
                            thumbnail_image: url,
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                {state.thumbnail_image && (
                  <>
                    <p>Thumbnail Image</p>{" "}
                    {/* <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={state.thumbnail_image}
                      style={{
                        margin: "auto",
                      }}
                    /> */}
                    <ImageWithPopup
                      src={state.thumbnail_image}
                      alt="Example Image"
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={4}>
                <CustomInputField
                  label="Main Image"
                  type="File"
                  fullWidth
                  onChange={(e) => {
                    const imageRef = ref(storage, `/images+${Math.random()}`);
                    uploadBytes(imageRef, e.target.files[0]).then(() => {
                      getDownloadURL(imageRef)
                        .then((url) => {
                          // localStorage.setItem("mainimg", url);
                          setState({
                            ...state,
                            main_image: url,
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                {state.main_image && (
                  <>
                    <p>Main Image</p>{" "}
                    {/* <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={state.main_image}
                      style={{
                        margin: "auto",
                      }}
                    /> */}
                    <ImageWithPopup
                      src={state.main_image}
                      alt="Example Image"
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                <CustomInputField
                  label="Written By"
                  fullWidth
                  value={state.written_by}
                  // onChange={(e) =>
                  //   setState({
                  //     ...state,
                  //     written_by: e.target.value,
                  //   })
                  // }
                  onChange={(e) => {
                    const input = e.target.value;
                    // Remove non-alphabetic characters
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    // Ensure input does not exceed 50 characters
                    const trimmedInput = validInput.slice(0, 50);
                    setState({
                      ...state,
                      written_by: trimmedInput,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInputField
                  label="Title"
                  fullWidth
                  value={state.title}
                  // onChange={(e) =>
                  //   setState({
                  //     ...state,
                  //     title: e.target.value,
                  //   })
                  // }
                  onChange={(e) => {
                    const input = e.target.value;
                    // Remove non-alphabetic characters
                    const validInput = input;
                    // Ensure input does not exceed 50 characters
                    const trimmedInput = validInput.slice(0, 50);
                    setState({
                      ...state,
                      title: trimmedInput,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <p>Date</p>
                <input
                  required=""
                  style={{
                    padding: "10px",
                    // borderColor: "rgba(148,158,171,0.32)",
                    boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                    width: "100%",
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                  }}
                  type="date"
                  value={state.date}
                  onChange={(e) =>
                    setState({
                      ...state,
                      date: e.target.value,
                    })
                  }
                  max={new Date().toISOString().split("T")[0]}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInputAndSelectField
                  // options={["male", "female", "others"]}
                  options={bloglist.map((item) => item.category_name)}
                  value={state.blog_cat}
                  label="Blog Category"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryType = bloglist.find(
                      (item) => item.category_name === v
                    );

                    setState((prevState) => ({
                      ...prevState,
                      blog_cat: v,
                      blogCat_id: selectedEntryType ? selectedEntryType.id : "",
                    }));
                    console.log(state);
                  }}
                  // Handle onChange event
                />
              </Grid>
              <Grid item xs={12}>
                <p>Description</p>
                <Editor
                  label="Description"
                  editorState={state.description}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) =>
                    setState({
                      ...state,
                      description: e,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInputField
                  label="Meta Title"
                  fullWidth
                  value={state.meta_title}
                  name="name"
                  onChange={(e) => {
                    setState({
                      ...state,
                      meta_title: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInputField
                  label="Meta Keywords"
                  fullWidth
                  value={state.meta_keywords}
                  name="name"
                  onChange={(e) => {
                    setState({
                      ...state,
                      meta_keywords: e.target.value,
                    });
                  }}
                />
              </Grid>
              {/* <Grid item xs={5}></Grid> */}
              <Grid item xs={12}>
                <CustomInputField
                  label="Meta Description"
                  fullWidth
                  value={state.meta_description}
                  name="name"
                  onChange={(e) => {
                    setState({
                      ...state,
                      meta_description: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.title &&
                        !!state.meta_title &&
                        !!state.meta_description &&
                        !!state.meta_keywords &&
                        !!state.thumbnail_image &&
                        !!state.description.getCurrentContent().hasText() &&
                        !!state.written_by &&
                        !!state.date &&
                        !!state.blogCat_id &&
                        !!state.visaId
                      ) {
                        handleUpdateVisaCategory(state.visaId);
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                    // onClick={(e) => {
                    //   handleUpdateVisaCategory(state.visaId);
                    // }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.title &&
                        !!state.meta_title &&
                        !!state.meta_description &&
                        !!state.meta_keywords &&
                        !!state.thumbnail_image &&
                        !!state.description.getCurrentContent().hasText() &&
                        !!state.written_by &&
                        !!state.date &&
                        !!state.blogCat_id
                      ) {
                        handleSaveVisaCategory();
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                    // onClick={(e) => {
                    //   handleSaveVisaCategory();
                    // }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          {/* <p className={classes.title}>Visa Lists</p>
          <Paper className={classes.paper}> */}
          {/* <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            /> */}
          {/* <CountryTable
              fullWidth
              cols={[
                "Sl No.",
                "Country Name",
                "Banner",
                "Flag",
                "Description",
                "written_by",
                "written_by Code",
                "Action",
              ]}
              rows={[
                ...bloglist
                  .sort()

                  .map((vs, idx) => [
                    idx + 1,
                    vs.country_name,
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={vs.thumbnail_image}
                      style={{
                        margin: "auto",
                      }}
                    />,
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={vs.main_image}
                      style={{
                        margin: "auto",
                      }}
                    />,
                    vs.description,
                    vs.written_by,
                    vs.date,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleVisaCategoryDelete(vs.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) =>
                          setState({
                            country_name: vs.country_name,
                            thumbnail_image: vs.thumbnail_image,
                            main_image: vs.main_image,
                            description: vs.description,
                            written_by: vs.written_by,
                            date: vs.date,
                            isUpdate: true,
                            visaId: vs.id,
                          })
                        }
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            /> */}
          {/* </Paper> */}
        </div>
      </div>
    </div>
  );
}
